import { createContext, useContext } from 'react';
import { ToasterContent, ToasterType } from 'components/toaster/Toaster';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { AccountService } from 'common/services/AccountService';
import { UserType } from 'common/interfaces/account.interface';
import { userInitialValue } from 'common/data/global.data';
import { FactoryType } from 'common/interfaces/factory.interface';
import { CommodityType } from 'common/interfaces/commodity.interface';

export interface AppContextType {
  user: UserType;
  factories: FactoryType[];
  commodities: CommodityType[];
  isAuthenticated: boolean;
  toaster: ToasterType;
  locale: string;
  setLanguage: (newLocale: string) => void;
  firebaseAuth?: firebase.auth.Auth;
  firestore?: firebase.firestore.Firestore;
  storage?: firebase.storage.Storage;
  accountService: AccountService;
}

const appContext: AppContextType = {
  user: userInitialValue,
  factories: [],
  commodities: [],
  isAuthenticated: false,
  toaster: ToasterContent,
  locale: 'en-US',
  setLanguage: () => {},
  accountService: new AccountService(''),
};

export const AppContext = createContext<AppContextType>(appContext);

export const useAppContext = () => useContext(AppContext);
