import { action, makeObservable, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { CommodityPricesType, CommodityPriceType, CommodityType } from 'common/interfaces/commodity.interface';
import { ChartType } from 'common/interfaces/chart.interface';

export class CommodityStore {
  commodityList: CommodityType[] = [];

  constructor() {
    makeObservable(this, {
      commodityList: observable,
      insertCommodity: action,
      setCommodityList: action,
      getCommodityList: action,
      removeCommodity: action,
    });
  }

  getCommodityList() {
    return this.commodityList;
  }

  setCommodityList(
    firestore: firebase.firestore.Firestore,
    setCommodities: React.Dispatch<React.SetStateAction<CommodityType[]>>,
  ) {
    const getCommodity = firestore
      .collection('commodity')
      .onSnapshot({ includeMetadataChanges: true }, (commodities) => {
        commodities.docs.map((commodity) => {
          const commodityData = commodity.data();

          const commodityPriceLists: CommodityPriceType[] = [];
          if (commodityData.prices) {
            commodityData.prices.map((price: CommodityPricesType) => {
              if (price.prices) {
                price.prices.map((priceDetail) => {
                  const commodityPriceObject = {
                    id: price.id,
                    commodity: commodityData.commodity,
                    price: priceDetail.prc,
                    open: priceDetail.prc,
                    high: priceDetail.prc,
                    low: priceDetail.prc,
                    close: priceDetail.prc,
                    timestamp: priceDetail.ts,
                  };
                  commodityPriceLists.push(commodityPriceObject);
                });
              }
            });
          }

          if (commodityData) {
            const commodityObject = {
              id: commodity.id,
              commodity: commodityData.commodity,
              displayCode: commodityData.displayCode,
              displayName: commodityData.displayName,
              price: commodityData.price,
              isPPN: commodityData.isPPN,
              description: '',
              timestamp: commodityData.timestamp,
              chartType: commodityData.displayCode == 'USD/IDR' ? ChartType.CANDLE : ChartType.LINE,
              priceChartCurrency: 'Rp',
              chartSymbol: commodityData.displayCode == 'USD/IDR' ? 'FX_IDC:USDIDR' : '',
              prices: commodityPriceLists ?? [],
            };

            // Remove duplicate array if update happened
            this.removeCommodity(commodity.id);
            this.insertCommodity(commodityObject);
            setCommodities(this.commodityList);
          }
        });
      });
    return () => getCommodity();
  }

  insertCommodity(commodityList: CommodityType) {
    this.commodityList.push(commodityList);
  }

  removeCommodity(deleted_id: string) {
    const updatedcommodityList = this.commodityList.filter((e) => e.id != deleted_id);
    this.commodityList = updatedcommodityList;
  }
}

export const commodityStore = new CommodityStore();
