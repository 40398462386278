import { useAppContext } from 'common/contexts/AppContext';
import {
  CheckGreenCircle,
  CheckRedCircle,
  CheckYellowCircle,
  GreenCross,
  RedCross,
  YellowCross,
} from 'components/icon/IconsElement';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ToasterContentType } from './Toaster';

interface ToasterType {
  toasterContent: ToasterContentType;
}

const ToasterItem: React.FC<ToasterType> = ({ toasterContent }: ToasterType) => {
  const appContext = useAppContext();
  let color: string = 'green';
  let backgroundColor: string = 'bg-green-600';
  let checkMark: JSX.Element = CheckRedCircle;
  let crossMark: JSX.Element = GreenCross;

  if (toasterContent.type === 'success') {
    backgroundColor = 'bg-green-600';
    checkMark = CheckGreenCircle;
    crossMark = GreenCross;
  } else if (toasterContent.type === 'warning') {
    backgroundColor = 'bg-yellow-600';
    checkMark = CheckYellowCircle;
    crossMark = YellowCross;
  } else {
    backgroundColor = 'bg-red-600';
    checkMark = CheckRedCircle;
    crossMark = RedCross;
  }

  useEffect(() => {
    if (toasterContent) {
      const toasterContentInterval = setInterval(function () {
        appContext.toaster.closeToaster(toasterContent.id);
        clearInterval(toasterContentInterval);
      }, toasterContent.interval);
    }
  }, []);

  const ToasterLink = () => {
    if (!toasterContent.linkLiteral && !toasterContent.linkPath) {
      return <></>;
    }
    return (
      <span>
        <NavLink to={toasterContent.linkPath!} onClick={() => appContext.toaster.closeToaster(toasterContent.id)}>
          {toasterContent.linkLiteral}
        </NavLink>
      </span>
    );
  };

  return (
    <div
      className={`lg:rounded-md ${backgroundColor} fixed top-0 left-1/2 -translate-x-1/2 transform mt-0 z-50 w-full md:max-w-sm p-4 md:p-4 max-h-screen overflow-hidden display-table`}
    >
      <div className="flex">
        <div className="flex-shrink-0">{checkMark}</div>
        <div className="ml-3">
          <p className={`text-sm font-medium text-white`}>
            {toasterContent.message}
            <ToasterLink />
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              id="close-toaster"
              className={`inline-flex ${backgroundColor} rounded-md p-1.5 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
              onClick={() => appContext.toaster.closeToaster(toasterContent.id)}
            >
              {crossMark}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToasterItem;
