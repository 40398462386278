import { LogoProperty, LogoVariantType } from 'components/logo/Logo';
import React from 'react';

interface AccountLayoutType {
  logo: React.FC<LogoProperty>;
  title: string;
  children: React.ReactNode;
}

const AccountLayout: React.FC<AccountLayoutType> = ({ logo, title, children }: AccountLayoutType) => {
  const Logo = logo;
  const logoVariant: LogoVariantType = LogoVariantType.MEDIUM;
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo variant={logoVariant} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900"> {title} </h2>
      </div>
      {children}
    </div>
  );
};

export default AccountLayout;
