import React, { useEffect, useState } from 'react';
import AccountForgetComponent from 'components/account/AccountForgetComponent';
import Logo from 'components/logo/Logo';
import { accountInputType } from 'common/interfaces/account.interface';
import { translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import { useAppContext } from 'common/contexts/AppContext';

const ForgetPasswordPage: React.FC = () => {
  const appContext = useAppContext();
  const intl = useIntl();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const signIn = {
    label: 'Sign-in instead?',
    link: '/account/sign-in',
    onClick: () => {},
  };
  const forget = {
    label: 'Forget password',
    link: '/account/forget-password',
    onClick: async (accountInput: accountInputType) => {
      let username = accountInput.username;
      const userResponse = await appContext.accountService.getUserCredential(username!);
      if (userResponse) {
        const forgetPasswordResponse = await appContext.accountService.forgetPassword(
          userResponse.result,
          `${process.env.REACT_APP_BASE_URL}/account/reset-password`,
        );
        if (forgetPasswordResponse) {
          const verificationId = forgetPasswordResponse.result.verificationCode;
          const verificationCode = forgetPasswordResponse.result.verificationCode;
          if (verificationId && verificationCode) {
            appContext.toaster.pushSuccessToaster('Reset password link has been sent to Your email address');
          }
        }
      }
      accountInput.setLoadingState(false);
    },
  };
  return (
    <>
      <AccountForgetComponent
        logo={Logo}
        title={translate(intl, 'accountForgetComponentTitle', 'Forget your password')}
        username="username"
        actions={{ signIn, forget }}
      />
    </>
  );
};

export default ForgetPasswordPage;
