import React, { useEffect, useRef, useState } from 'react';
import { createChart, IChartApi, ISeriesApi } from 'lightweight-charts';
import { ChartType, ChartDataType } from 'common/interfaces/chart.interface';
import TradingViewWidget from 'react-tradingview-widget';
import { useAppContext } from 'common/contexts/AppContext';

export interface TVChartComponentType {
  payload: {
    id: string;
    chartType: ChartType;
    symbol?: string;
    priceCurrency?: string;
    data?: ChartDataType[];
  };
}

const TVChartComponent: React.FC<TVChartComponentType> = ({ payload }: TVChartComponentType) => {
  const [returnElement, setReturnElement] = useState<React.ReactElement>(<div />);
  const [chart, setChart] = useState<IChartApi>();
  const [chartComponent, setChartComponent] = useState<ISeriesApi<'Line'> | ISeriesApi<'Candlestick'>>();
  const [chartElement, setChartElement] = useState<HTMLElement>(document.getElementById(payload.id)!);

  useEffect(() => {
    setChartElement(document.getElementById(payload.id)!);
  }, []);

  useEffect(() => {
    if (chart && chartElement) {
      chart.applyOptions({
        timeScale: {
          barSpacing: 58,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          tickMarkFormatter: (time: any) => {
            return String(`${time.year}/${time.month}/${time.day}`);
          },
        },
      });
      setChartComponent(payload.chartType === ChartType.LINE ? chart.addLineSeries() : chart.addCandlestickSeries());

      const handleResize = () => {
        chart.applyOptions({
          width: chartElement.clientWidth,
          height: chartElement.clientHeight,
        });
      };
      window.addEventListener('resize', handleResize);
    }
  }, [chart, chartElement]);

  useEffect(() => {
    const chartElement = document.getElementById(payload.id)!;
    if (chartElement) {
      if (payload.symbol) {
        setReturnElement(
          <div className="h-full py-1 px-1">
            <TradingViewWidget
              symbol={payload.symbol}
              width={chartElement.clientWidth}
              height={chartElement.clientHeight}
              style={payload.chartType}
              autosize
            />
          </div>,
        );
      } else {
        if (chart) {
          chart.remove();
        }
        setChart(
          createChart(chartElement, {
            handleScroll: {
              vertTouchDrag: false,
            },
            width: chartElement.clientWidth,
            height: 350,
            localization: {
              dateFormat: 'yyyy/MM/dd',
              priceFormatter: (price: number) => `${payload.priceCurrency}.${price.toFixed(2)}`,
            },
          }),
        );
      }
    }
  }, [payload.data, chartElement]);

  useEffect(() => {
    if (chartComponent && payload.data) {
      chartComponent.setData(payload.data);
    }
  }, [chartComponent]);

  return returnElement;
};

export default TVChartComponent;
