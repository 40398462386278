import { IntlShape } from 'react-intl';
import moment from 'moment';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { ChartDataType } from 'common/interfaces/chart.interface';
import {
  BuildingActive,
  BuildingInactive,
  ContractActive,
  ContractInactive,
  HomeActive,
  HomeInactive,
  SettingActive,
  SettingInactive,
} from 'components/icon/IconsElement';

// Ex: 12500 -> Rp.12.500,00
export const convertIDRFormat = (amount: number) => {
  const fixedAmount = amount.toFixed(2);
  const splittedAmount = fixedAmount.toString().split('.');
  const formattedAmount = splittedAmount[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `Rp.${formattedAmount},${splittedAmount[1] ?? '00'}`;
};

export const getPriceChangesProperty = (lastestPrice: number, priceBeforeLatest: number) => {
  let changes = lastestPrice - priceBeforeLatest;
  let bgClassName = '';
  let changesLabel = '';
  let fontColor = '';
  if (changes >= 0) {
    if (changes > 0) {
      bgClassName = 'bg-sss-300';
      fontColor = 'text-sss-200';
    } else {
      bgClassName = 'bg-sss-orange';
      fontColor = 'text-sss-orange';
    }
    changesLabel = `+${convertIDRFormat(changes)}`;
  } else {
    bgClassName = 'bg-sss-red';
    fontColor = 'text-sss-red';
    changesLabel = `-${convertIDRFormat(changes).replace('-', '')}`;
  }
  return {
    changes,
    bgClassName,
    fontColor,
    changesLabel,
  };
};

export const translate = (intl: IntlShape, translateId: string, defaultMessage: string = '') => {
  const intlParam = {
    id: translateId,
    defaultMessage: defaultMessage || translateId,
  };
  return intl.formatMessage(intlParam);
};

export const convertDateFromString = (dateTime: string, dateFormat: string = 'YYYY-MM-DD') => {
  return moment(dateTime).format(dateFormat);
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const setNavigationActive = (pageNavigations: RoutesType[], activeLink: string) => {
  pageNavigations.map((navigation) => {
    if (navigation.link === activeLink) {
      navigation.isActive = true;
      if (navigation.label === 'Dashboard') {
        navigation.icon = HomeActive;
      } else if (navigation.label === 'Factories') {
        navigation.icon = BuildingActive;
      } else if (navigation.label === 'Contracts') {
        navigation.icon = ContractActive;
      } else {
        navigation.icon = SettingActive;
      }
    } else {
      navigation.isActive = false;
      if (navigation.label === 'Dashboard') {
        navigation.icon = HomeInactive;
      } else if (navigation.label === 'Factories') {
        navigation.icon = BuildingInactive;
      } else if (navigation.label === 'Contracts') {
        navigation.icon = ContractInactive;
      } else {
        navigation.icon = SettingInactive;
      }
    }
  });
  return pageNavigations;
};

export const filterChartDataWithSameTimePropertyValue = (array: ChartDataType[], isReverseFirst: boolean = false) => {
  if (isReverseFirst) {
    return array.reverse().filter((ele, ind) => ind === array.findIndex((elem) => elem.time === ele.time));
  } else {
    return array.filter((ele, ind) => ind === array.findIndex((elem) => elem.time === ele.time));
  }
};

export const postFetch = (url: string, postData: {}): Promise<any> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: postData,
    }),
  };
  return fetch(url, requestOptions).then((response) => response.json());
};

export const numberFormat = (parsedNumber: string) => {
  return new Intl.NumberFormat('en-us').format(parseInt(parsedNumber));
};
