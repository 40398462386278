import { action, makeObservable, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  ContractHistoryPayloadType,
  ContractListItemStatus,
  ContractListItemType,
} from 'common/interfaces/contract.interface';
import { convertDateFromString, filterChartDataWithSameTimePropertyValue } from 'common/utils/global.utils';
import { SetStateAction } from 'react';
import { ChartDataType, ChartType } from 'common/interfaces/chart.interface';

export class ContractStore {
  contractList: ContractListItemType[] = [];

  constructor() {
    makeObservable(this, {
      contractList: observable,
      insertContract: action,
      setContractList: action,
      getContractList: action,
      removeContract: action,
    });
  }

  getContractList() {
    return this.contractList;
  }

  setContractList(
    firestore: firebase.firestore.Firestore,
    setContracts: React.Dispatch<React.SetStateAction<ContractListItemType[]>>,
    setContractHistoryPayload: React.Dispatch<SetStateAction<ContractHistoryPayloadType>>,
    factoryId: string,
  ) {
    if (factoryId) {
      if (firestore) {
        const getContract = firestore
          .collection('contracts')
          .orderBy('timestamp', 'desc')
          .where('factory._ref', '==', factoryId)
          .onSnapshot((contracts) => {
            if (contracts.docs.length) {
              contracts.docs.map((contract) => {
                const contractData = contract.data();
                const contractObject = {
                  id: contract.id,
                  price: contractData.price,
                  timestamp: convertDateFromString(contractData.timestamp, 'MMMM DD, YYYY HH:mm:ss'),
                  quantity: contractData.quantity,
                  quantityUnit: 'MT',
                  status: contractData.isCanceled ? ContractListItemStatus.CANCELLED : ContractListItemStatus.COMPLETED,
                  action: {
                    label: 'View',
                    link: `/contract/detail/${factoryId}`,
                  },
                };
                // Remove duplicate array if update happened
                this.removeContract(contract.id);
                this.insertContract(contractObject);
                setContracts(this.contractList);
              });
              if (this.contractList.length) {
                let contractHistoryChartData: ChartDataType[] = [];
                this.contractList.map((contractHistory) => {
                  contractHistoryChartData.push({
                    time: convertDateFromString(contractHistory.timestamp),
                    value: contractHistory.price,
                    open: contractHistory.price,
                    high: contractHistory.price,
                    low: contractHistory.price,
                    close: contractHistory.price,
                  });
                });

                //Filter chart data with same time value.
                contractHistoryChartData = filterChartDataWithSameTimePropertyValue(contractHistoryChartData);

                setContractHistoryPayload({
                  id: `contract-history-chart-${factoryId}`,
                  chartType: ChartType.LINE,
                  priceCurrency: 'Rp',
                  data: contractHistoryChartData.reverse(),
                });
              }
            }
          });
        return () => getContract();
      }
    }
  }

  insertContract(contractList: ContractListItemType) {
    this.contractList.push(contractList);
  }

  removeContract(deleted_id: string) {
    const updatedcontractList = this.contractList.filter((e) => e.id != deleted_id);
    this.contractList = updatedcontractList;
  }
}

export const contractStore = new ContractStore();
