import { useAppContext } from 'common/contexts/AppContext';
import { translate } from 'common/utils/global.utils';
import React from 'react';
import { useIntl } from 'react-intl';

const SettingsProfileComponent: React.FC = () => {
  const intl = useIntl();
  const appContext = useAppContext();
  return (
    <section id="profile-setting">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                {translate(intl, 'PROFILE_SETTING_TITLE')}
              </h2>
              <p className="mt-1 text-sm text-gray-500">{translate(intl, 'PROFILE_SETTING_DESCRIPTION')}</p>
            </div>

            <div className="mt-6 grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                  {translate(intl, 'FIRST_NAME')}
                </label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="cc-given-name"
                  value={appContext.user.firstname!}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-sss-500 sm:text-sm"
                  readOnly={true}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                  {translate(intl, 'LAST_NAME')}
                </label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={appContext.user.lastname!}
                  autoComplete="cc-family-name"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-sss-500 sm:text-sm"
                  readOnly={true}
                />
              </div>

              <div className="col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                  {translate(intl, 'EMAIL_ADDRESS')}
                </label>
                <input
                  type="text"
                  name="email_address"
                  id="email_address"
                  value={appContext.user.email!}
                  autoComplete="email"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-sss-500 sm:text-sm"
                  readOnly={true}
                />
              </div>

              <div className="col-span-4">
                <label htmlFor="mobile_number" className="block text-sm font-medium text-gray-700">
                  {translate(intl, 'MOBILE_NUMBER')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <select
                      id="country"
                      name="country"
                      className="focus:ring-sss-500 focus:border-sss-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                      value={appContext.user.countryCode!}
                      onChange={() => {}}
                      disabled={true}
                    >
                      <option value="+60">+60</option>
                      <option value="+62">+62</option>
                      <option value="+65">+65</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="mobile_number"
                    id="mobile_number"
                    value={appContext.user.phoneNumber!}
                    className="focus:ring-sss-500 focus:border-sss-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                    placeholder="8123456789"
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SettingsProfileComponent;
