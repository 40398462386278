import { postFetch } from 'common/utils/global.utils';

interface ReturnType {
  result: {
    verificationId: string;
    verificationCode: string;
    emailVerified: boolean;
    displayName: string;
    error: {
      message: string;
    };
  };
  error: {
    message: string;
  };
}

export class AccountService {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  signIn(username: string, password: string): Promise<ReturnType> {
    return postFetch(`${this.apiUrl}/signIn`, {
      username: username,
      password: password,
    });
  }

  checkOtp(verificationId: string, verificationCode: string): Promise<{ result: string; error?: { message: string } }> {
    return postFetch(`${this.apiUrl}/checkOtpCode`, {
      verificationId,
      verificationCode,
    });
  }

  updatePassword(password: string, uid: string): Promise<{ result: string; error?: { message: string } }> {
    return postFetch(`${this.apiUrl}/updateUserPassword`, {
      password,
      uid,
    });
  }

  resendOtp(verificationId: string, username: string, verificationMethod: string): Promise<ReturnType> {
    return postFetch(`${this.apiUrl}/resendOtpCode`, {
      verificationId,
      username,
      verificationMethod,
    });
  }

  getUserCredential(username: string): Promise<{ result: string }> {
    return postFetch(`${this.apiUrl}/getUserCredential`, { username });
  }

  forgetPassword(uid: string, forgetPasswordLink: string): Promise<ReturnType> {
    return postFetch(`${this.apiUrl}/forgetPassword`, {
      uid,
      forgetPasswordLink,
    });
  }

  resetPassword(verificationId: string, verificationCode: string, password: string): Promise<{ result: string }> {
    return postFetch(`${this.apiUrl}/resetPassword`, {
      verificationId,
      verificationCode,
      password,
    });
  }
}
