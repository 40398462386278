import React from 'react';
import DashboardTabBarNavigationIcon from 'components/icon/DashboardTabBarNavigationIcon';
import FactoryTabBarNavigationIcon from 'components/icon/FactoryTabBarNavigationIcon';
import ContractTabBarNavigationIcon from 'components/icon/ContractTabBarNavigationIcon';
import SettingTabBarNavigationIcon from 'components/icon/SettingTabBarNavigationIcon';
import { RoutesType } from 'common/interfaces/navigation.interface';
import {
  BalanceScale,
  Bell,
  BuildingInactive,
  ContractInactive,
  Facebook,
  HomeInactive,
  Instagram,
  Lock,
  SettingInactive,
  Twitter,
  UserIcon,
  Whatsapp,
} from 'components/icon/IconsElement';
export const settingsNavigationProp: RoutesType[] = [
  {
    label: 'PROFILE',
    link: '#profile-setting',
    isActive: false,
    target: '',
    icon: UserIcon,
  },
  {
    label: 'NOTIFICATION',
    link: '#notification-setting',
    isActive: false,
    target: '',
    icon: Bell,
  },
  {
    label: 'PRIVACY_POLICY',
    link: '/privacy',
    isActive: false,
    target: '_BLANK',
    icon: Lock,
  },
  {
    label: 'TERMS_CONDITIONS',
    link: '/terms',
    isActive: false,
    target: '_BLANK',
    icon: BalanceScale,
  },
];

export const simpleNavigationActionsProp: RoutesType[] = [
  {
    label: 'Dashboard',
    link: '/',
    isActive: false,
    icon: HomeInactive,
  },
  {
    label: 'Factories',
    link: '/factories',
    isActive: false,
    icon: BuildingInactive,
  },
  {
    label: 'Contracts',
    link: '/contracts',
    isActive: false,
    icon: ContractInactive,
  },
  {
    label: 'Settings',
    link: '/settings',
    isActive: false,
    icon: SettingInactive,
  },
];
export const authenticatedActions = {
  profile: {
    label: 'Your Profile',
    link: '/settings#profile-setting',
  },
  setting: {
    label: 'Settings',
    link: '/settings',
  },
  signout: {
    label: 'Sign Out',
    link: '/signout',
  },
};

export const userInitialValue = {
  uid: '',
  displayName: '',
  firstname: '',
  lastname: '',
  email: '',
  factoryIds: [],
  countryCode: '',
  phoneNumber: '',
  photoUrl: '',
  pushNotifications: false,
  language: 'en-US',
};

export const guestActions = {
  signin: {
    label: 'Sign In',
    link: '/account/sign-in',
  },
};

export const iconsActions = [
  {
    label: 'Whatsapp',
    link: 'https://web.whatsapp.com/',
    icon: Whatsapp,
  },
  {
    label: 'Facebook',
    link: 'https://www.facebook.com/',
    icon: Facebook,
  },
  {
    label: 'Instagram',
    link: 'https://www.instagram.com/',
    icon: Instagram,
  },
  {
    label: 'Twitter',
    link: 'https://www.twitter.com/',
    icon: Twitter,
  },
];
