import React, { createRef, useEffect, useState } from 'react';
import { LogoProperty } from 'components/logo/Logo';
import AccountUsernameComponent from 'components/input/AccountUsernameComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { RoutesType, UsernameType } from 'common/interfaces/account.interface';
import { translate } from 'common/utils/global.utils';
import AccountLayout from 'components/account/AccountLayout';
import { useForm } from 'react-hook-form';
import LoadingSpinner from 'components/animation/LoadingSpinner';

interface SignInFormType {
  countryCode?: string;
  username: string;
  password: string;
  rememberMe: boolean;
}

interface AccountSignInProperty {
  logo: React.FC<LogoProperty>;
  title: string;
  username: string;
  actions?: RoutesType;
}

const AccountSignInComponent: React.FC<AccountSignInProperty> = ({
  logo,
  title,
  username,
  actions,
}: AccountSignInProperty) => {
  const inputRef = createRef<HTMLInputElement>();
  const buttonRef = createRef<HTMLButtonElement>();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputRef.current && buttonRef.current) {
        inputRef.current.blur();
        buttonRef.current.click();
      }
    }
  };

  const intl = useIntl();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [usernameInputType, setUsernameInputType] = useState(UsernameType.MOBILE);
  const [countryCode, setCountryCode] = useState<string>('+62');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignInFormType>();
  let registerUsernameField = register('username', { required: true, pattern: /^[0-9]+$/ });
  if (usernameInputType === UsernameType.EMAIL) {
    registerUsernameField = register('username', {
      required: true,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    });
  }
  const Logo = logo;

  useEffect(() => {
    const usernameRememberMe = localStorage.getItem('usernameRememberMe');
    if (usernameRememberMe) {
      setValue('username', usernameRememberMe.replace('+62', ''));
      setValue('rememberMe', true);
    }
  }, []);

  return (
    <AccountLayout logo={logo} title={title}>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            autoComplete="off"
            onSubmit={handleSubmit((signInFormData) => {
              setLoadingState(true);
              if (actions && actions.signIn) {
                actions.signIn.onClick({
                  username:
                    usernameInputType === UsernameType.MOBILE
                      ? `${countryCode}${signInFormData.username}`
                      : signInFormData.username,
                  usernameType: usernameInputType,
                  password: signInFormData.password,
                  rememberMe: signInFormData.rememberMe,
                  setLoadingState,
                });
              }
            })}
          >
            <AccountUsernameComponent
              Logo={Logo}
              title={translate(intl, 'accountUsernameComponentTitle', 'Email/Mobile Phone')}
              usernameTypes={usernameInputType}
              allowedMobile={['+62', '+63']}
              actions={{ setUsernameInputType }}
              registerUsername={registerUsernameField}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
            {errors.username && errors.username.type === 'pattern' && (
              <span className="text-red-500 text-sm">
                {usernameInputType === UsernameType.EMAIL
                  ? translate(intl, 'USERNAME_INVALID_EMAIL_ERROR')
                  : translate(intl, 'USERNAME_INVALID_PHONE_ERROR')}
              </span>
            )}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                <FormattedMessage id="PASSWORD" defaultMessage="password" />
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  {...register('password', { required: true })}
                  ref={inputRef}
                  type="password"
                  autoComplete="current-password"
                  required={true}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sss-500 focus:border-sss-500 sm:text-sm"
                  placeholder="Enter your password"
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  {...register('rememberMe')}
                  type="checkbox"
                  className="h-4 w-4 text-sss-600 focus:ring-sss-500 border-gray-300 rounded"
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  <FormattedMessage id="rememberMe" defaultMessage="Remember me" />
                </label>
              </div>
            </div>
            <div className="mx-auto" id="recaptcha-container" />

            <div>
              <button
                ref={buttonRef}
                type="submit"
                disabled={loadingState}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sss-300 hover:bg-sss-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500"
              >
                {loadingState ? <LoadingSpinner /> : ''}
                Sign in
              </button>

              <div className="text-sm">
                <NavLink
                  to="/account/forget-password"
                  exact={true}
                  className="font-medium w-full flex justify-center pt-4 text-sss-600 hover:text-sss-500"
                >
                  <FormattedMessage id="RESET_PASSWORD_LINK" defaultMessage="Reset your password" />
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default AccountSignInComponent;
