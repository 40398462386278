import React from 'react';
import Image from 'images/icon-transparent.png';
import { useHistory } from 'react-router';

export enum LogoVariantType {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  HOME_PAGE = 'HOME_PAGE',
}

export interface LogoProperty {
  variant: LogoVariantType;
  link?: string;
}

const Logo: React.FC<LogoProperty> = ({ variant, link }: LogoProperty) => {
  const history = useHistory();
  const cursorPointer = link ? 'cursor-pointer' : '';
  return (
    <div>
      {variant === LogoVariantType.SMALL && (
        <div
          className={`flex-shrink-0 flex items-center pt-4 pb-4 ${cursorPointer}`}
          onClick={() => history.push(link!)}
        >
          <img className="mx-auto h-12 w-auto" src={Image} alt="BBI" />
        </div>
      )}
      {variant === LogoVariantType.MEDIUM && (
        <div className={`sm:mx-auto sm:w-full sm:max-w-md ${cursorPointer}`} onClick={() => history.push(link!)}>
          <img className="mx-auto h-24 w-auto" src={Image} alt="BBI" />
        </div>
      )}
      {variant === LogoVariantType.HOME_PAGE && (
        <div
          className={`sm:mx-auto sm:w-full sm:max-w-md lg:mt-10 lg:mb-10 sm:mt-80 ${cursorPointer}`}
          onClick={() => history.push(link!)}
        >
          <img className="mx-auto h-auto lg:w-auto sm:w-full sm:align-middle" src={Image} alt="BBI" />
        </div>
      )}
    </div>
  );
};

export default Logo;
