import React, { useState } from 'react';
import { LogoProperty } from 'components/logo/Logo';
import AccountUsernameComponent from 'components/input/AccountUsernameComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { RoutesType, UsernameType } from 'common/interfaces/account.interface';
import { translate } from 'common/utils/global.utils';
import AccountLayout from 'components/account/AccountLayout';
import { useForm } from 'react-hook-form';
import LoadingSpinner from 'components/animation/LoadingSpinner';

interface ForgetPasswordFormType {
  countryCode: string;
  username: string;
}

interface AccountForgetProperty {
  logo: React.FC<LogoProperty>;
  title: string;
  username: string;
  actions?: RoutesType;
}

const AccountForgetComponent: React.FC<AccountForgetProperty> = ({
  logo,
  title,
  username,
  actions,
}: AccountForgetProperty) => {
  const intl = useIntl();
  const [usernameInputType, setUsernameInputType] = useState(UsernameType.MOBILE);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('+62');

  const Logo = logo;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgetPasswordFormType>();
  const registerUsernameForm = register('username', { required: true });

  return (
    <AccountLayout logo={logo} title={title}>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            autoComplete="off"
            onSubmit={handleSubmit((forgetPasswordFormData) => {
              setLoadingState(true);
              if (actions && actions.forget) {
                actions.forget.onClick({
                  username:
                    usernameInputType === UsernameType.MOBILE
                      ? `${forgetPasswordFormData.countryCode}${forgetPasswordFormData.username}`
                      : forgetPasswordFormData.username,
                  usernameType: usernameInputType,
                  setLoadingState,
                });
              }
            })}
          >
            <AccountUsernameComponent
              Logo={Logo}
              title={translate(intl, 'accountUsernameComponentTitle', 'Email/Mobile Phone')}
              usernameTypes={usernameInputType}
              allowedMobile={['+62', '+63']}
              actions={{ setUsernameInputType }}
              registerUsername={registerUsernameForm}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
            {errors.username && errors.username.type === 'pattern' && (
              <span className="text-red-500 text-sm">
                {usernameInputType === UsernameType.EMAIL
                  ? translate(intl, 'USERNAME_INVALID_EMAIL_ERROR')
                  : translate(intl, 'USERNAME_INVALID_PHONE_ERROR')}
              </span>
            )}
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <NavLink to="/account/sign-in" exact={true} className="font-medium text-sss-600 hover:text-sss-500">
                  <FormattedMessage id="linkToSignInQuestion" defaultMessage="Sign-in instead?" />
                </NavLink>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={loadingState}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sss-300 hover:bg-sss-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500"
              >
                {loadingState ? <LoadingSpinner /> : ''}
                <FormattedMessage id="forgetPassword" defaultMessage="Forget password" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default AccountForgetComponent;
