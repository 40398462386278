import React, { ReactElement } from 'react';
import {
  FaGithub,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaDiscord,
  FaBalanceScale,
  FaRegBell,
  FaRegUserCircle,
  FaLock,
  FaBars,
  FaSearch,
  FaCheckCircle,
  FaTimes,
  FaHome,
  FaRegBuilding,
  FaCog,
  FaRegFileAlt,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { BiErrorCircle } from 'react-icons/bi';
import {
  HiOutlineInbox,
  HiOutlineSparkles,
  HiGlobeAlt,
  HiOutlineLightningBolt,
  HiOutlineAnnotation,
  HiOutlineMail,
  HiOutlineCloudUpload,
  HiOutlineLockClosed,
  HiOutlineRefresh,
  HiOutlineShieldCheck,
  HiOutlineCog,
} from 'react-icons/hi';

export enum Icons {
  WHATSAPP,
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  GITHUB,
  BELL,
  SEARCH,
  LOCK,
  USER,
  DISCORD,
  OUTLINE_INBOX,
  OUTLINE_SPARKLES,
  BALANCE_SCALE,
  GLOBE_ALT,
  OUTLINE_LIGHTNINGBOLT,
  OUTLINE_ANNOTATION,
  OUTLINE_MAIL,
  OUTLINE_CLOUD_UPLOAD,
  OUTLINE_LOCK_CLOSED,
  OUTLINE_REFRESH,
  OUTLINE_SHIELD_CHECK,
  OUTLINE_COG,
  OUTLINE_SERVER,
  MOBILE_MENU,
  CHECK_RED_CIRCLE,
  CHECK_YELLOW_CIRCLE,
  CHECK_GREEN_CIRCLE,
  RED_CROSS,
  YELLOW_CROSS,
  GREEN_CROSS,
  HOME_ACTIVE,
  HOME_INACTIVE,
  BUILDING_ACTIVE,
  BUILDING_INACTIVE,
  CONTRACT_ACTIVE,
  CONTRACT_INACTIVE,
  SETTING_ACTIVE,
  SETTING_INACTIVE,
  MAP_MARKER,
}

export interface IconsType {
  type: Icons;
}

export const IconsElement = ({ type }: IconsType): ReactElement => {
  const DefaultIconClassName = 'h-6 w-6';
  let iconObj: ReactElement;
  switch (type) {
    case Icons.WHATSAPP:
      iconObj = <FaWhatsapp className={DefaultIconClassName} />;
      break;
    case Icons.FACEBOOK:
      iconObj = <FaFacebook className={DefaultIconClassName} />;
      break;
    case Icons.INSTAGRAM:
      iconObj = <FaInstagram className={DefaultIconClassName} />;
      break;
    case Icons.TWITTER:
      iconObj = <FaTwitter className={DefaultIconClassName} />;
      break;
    case Icons.BELL:
      iconObj = <FaRegBell className={`${DefaultIconClassName} mr-2 text-gray-400`} />;
      break;
    case Icons.SEARCH:
      iconObj = <FaSearch className={`h-4 w-4 mr-2 text-gray-400`} />;
      break;
    case Icons.LOCK:
      iconObj = <FaLock className={`${DefaultIconClassName} mr-2 text-gray-400`} />;
      break;
    case Icons.USER:
      iconObj = <FaRegUserCircle className={`${DefaultIconClassName} mr-2 text-gray-400`} />;
      break;
    case Icons.DISCORD:
      iconObj = <FaDiscord />;
      break;
    case Icons.GITHUB:
      iconObj = <FaGithub />;
      break;
    case Icons.OUTLINE_INBOX:
      iconObj = <HiOutlineInbox className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_SPARKLES:
      iconObj = <HiOutlineSparkles className={DefaultIconClassName} />;
      break;
    case Icons.BALANCE_SCALE:
      iconObj = <FaBalanceScale className={`${DefaultIconClassName} mr-2 text-gray-400`} />;
      break;
    case Icons.GLOBE_ALT:
      iconObj = <HiGlobeAlt className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_LIGHTNINGBOLT:
      iconObj = <HiOutlineLightningBolt className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_ANNOTATION:
      iconObj = <HiOutlineAnnotation className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_MAIL:
      iconObj = <HiOutlineMail className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_CLOUD_UPLOAD:
      iconObj = <HiOutlineCloudUpload className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_LOCK_CLOSED:
      iconObj = <HiOutlineLockClosed className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_REFRESH:
      iconObj = <HiOutlineRefresh className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_SHIELD_CHECK:
      iconObj = <HiOutlineShieldCheck className={DefaultIconClassName} />;
      break;
    case Icons.OUTLINE_COG:
      iconObj = <HiOutlineCog className={DefaultIconClassName} />;
      break;
    case Icons.MOBILE_MENU:
      iconObj = <FaBars className={`${DefaultIconClassName} text-gray-400`} />;
      break;
    case Icons.CHECK_RED_CIRCLE:
      iconObj = <FaCheckCircle className={`${DefaultIconClassName} text-red-400`} />;
      break;
    case Icons.CHECK_YELLOW_CIRCLE:
      iconObj = <FaCheckCircle className={`${DefaultIconClassName} text-yellow-400`} />;
      break;
    case Icons.CHECK_GREEN_CIRCLE:
      iconObj = <FaCheckCircle className={`${DefaultIconClassName} text-green-400`} />;
      break;
    case Icons.RED_CROSS:
      iconObj = <FaTimes className={`${DefaultIconClassName} text-red-400`} />;
      break;
    case Icons.YELLOW_CROSS:
      iconObj = <FaTimes className={`${DefaultIconClassName} text-yellow-400`} />;
      break;
    case Icons.GREEN_CROSS:
      iconObj = <FaTimes className={`${DefaultIconClassName} text-green-400`} />;
      break;
    case Icons.HOME_ACTIVE:
      iconObj = <FaHome className={`w-5 h-5 mb-1 mr-2 inline-block text-sss-200`} />;
      break;
    case Icons.HOME_INACTIVE:
      iconObj = <FaHome className={`w-5 h-5 mb-1 mr-2 inline-block text-gray-400`} />;
      break;
    case Icons.BUILDING_ACTIVE:
      iconObj = <FaRegBuilding className={`w-5 h-5 mb-1 mr-2 inline-block text-sss-200`} />;
      break;
    case Icons.BUILDING_INACTIVE:
      iconObj = <FaRegBuilding className={`w-5 h-5 mb-1 mr-2 inline-block text-gray-400`} />;
      break;
    case Icons.CONTRACT_ACTIVE:
      iconObj = <FaRegFileAlt className={`w-5 h-5 mb-1 mr-2 inline-block text-sss-200`} />;
      break;
    case Icons.CONTRACT_INACTIVE:
      iconObj = <FaRegFileAlt className={`w-5 h-5 mb-1 mr-2 inline-block text-gray-400`} />;
      break;
    case Icons.SETTING_ACTIVE:
      iconObj = <FaCog className={`w-5 h-5 mb-1 mr-2 inline-block text-sss-200`} />;
      break;
    case Icons.SETTING_INACTIVE:
      iconObj = <FaCog className={`w-5 h-5 mb-1 mr-2 inline-block text-gray-400`} />;
      break;
    case Icons.MAP_MARKER:
      iconObj = <FaMapMarkerAlt className={`w-5 h-5 mb-1 mr-2 inline-block text-gray-400`} />;
      break;
    case Icons.OUTLINE_SERVER:
      iconObj = (
        <svg
          className="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
          ></path>
        </svg>
      );
      break;
    default:
      iconObj = <BiErrorCircle />;
      break;
  }
  return <>{iconObj}</>;
};

export const Whatsapp = <IconsElement type={Icons.WHATSAPP} />;
export const Facebook = <IconsElement type={Icons.FACEBOOK} />;
export const Instagram = <IconsElement type={Icons.INSTAGRAM} />;
export const Twitter = <IconsElement type={Icons.TWITTER} />;
export const Github = <IconsElement type={Icons.GITHUB} />;
export const Discord = <IconsElement type={Icons.DISCORD} />;
export const Bell = <IconsElement type={Icons.BELL} />;
export const Search = <IconsElement type={Icons.SEARCH} />;
export const UserIcon = <IconsElement type={Icons.USER} />;
export const Lock = <IconsElement type={Icons.LOCK} />;
export const MobileMenu = <IconsElement type={Icons.MOBILE_MENU} />;
export const CheckRedCircle = <IconsElement type={Icons.CHECK_RED_CIRCLE} />;
export const CheckYellowCircle = <IconsElement type={Icons.CHECK_YELLOW_CIRCLE} />;
export const CheckGreenCircle = <IconsElement type={Icons.CHECK_GREEN_CIRCLE} />;
export const RedCross = <IconsElement type={Icons.RED_CROSS} />;
export const YellowCross = <IconsElement type={Icons.YELLOW_CROSS} />;
export const GreenCross = <IconsElement type={Icons.GREEN_CROSS} />;
export const HomeActive = <IconsElement type={Icons.HOME_ACTIVE} />;
export const HomeInactive = <IconsElement type={Icons.HOME_INACTIVE} />;
export const BuildingActive = <IconsElement type={Icons.BUILDING_ACTIVE} />;
export const BuildingInactive = <IconsElement type={Icons.BUILDING_INACTIVE} />;
export const ContractActive = <IconsElement type={Icons.CONTRACT_ACTIVE} />;
export const ContractInactive = <IconsElement type={Icons.CONTRACT_INACTIVE} />;
export const SettingActive = <IconsElement type={Icons.SETTING_ACTIVE} />;
export const SettingInactive = <IconsElement type={Icons.SETTING_INACTIVE} />;
export const MapMarker = <IconsElement type={Icons.MAP_MARKER} />;
export const OutlineInbox = <IconsElement type={Icons.OUTLINE_INBOX} />;
export const OutlineSparkles = <IconsElement type={Icons.OUTLINE_SPARKLES} />;
export const BalanceScale = <IconsElement type={Icons.BALANCE_SCALE} />;
export const GlobeAlt = <IconsElement type={Icons.GLOBE_ALT} />;
export const OutlineLightningBolt = <IconsElement type={Icons.OUTLINE_LIGHTNINGBOLT} />;
export const OutlineAnnotation = <IconsElement type={Icons.OUTLINE_ANNOTATION} />;
export const OutlineMail = <IconsElement type={Icons.OUTLINE_MAIL} />;
export const OutlineCloudUpload = <IconsElement type={Icons.OUTLINE_CLOUD_UPLOAD} />;
export const OutlineLockClosed = <IconsElement type={Icons.OUTLINE_LOCK_CLOSED} />;
export const OutlineRefresh = <IconsElement type={Icons.OUTLINE_REFRESH} />;
export const OutlineShieldCheck = <IconsElement type={Icons.OUTLINE_SHIELD_CHECK} />;
export const OutlineCog = <IconsElement type={Icons.OUTLINE_COG} />;
export const OutlineServer = <IconsElement type={Icons.OUTLINE_SERVER} />;

export default IconsElement;
