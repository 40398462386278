import { useAppContext } from 'common/contexts/AppContext';
import React from 'react';
import ToasterItem from './ToasterItem';

export interface ToasterContentType {
  id: string;
  type: string;
  message: string;
  linkLiteral?: string;
  linkPath?: string;
  interval: number;
}

export const ToasterContent = {
  content: [],
  pushToaster: (type: string, message: string, linkLiteral: string, linkPath: string, interval: number = 0) => {},
  pushSuccessToaster: (message: string, linkLiteral: string = '', linkPath: string = '') => {},
  pushWarningToaster: (message: string, linkLiteral: string = '', linkPath: string = '') => {},
  pushErrorToaster: (message: string, linkLiteral: string = '', linkPath: string = '') => {},
  closeToaster: (id: string) => {},
};

export interface ToasterType {
  content: Array<ToasterContentType>;
  pushToaster: (type: string, message: string, linkLiteral: string, linkPath: string, interval?: number) => void;
  pushSuccessToaster: (message: string, linkLiteral?: string, linkPath?: string) => void;
  pushWarningToaster: (message: string, linkLiteral?: string, linkPath?: string) => void;
  pushErrorToaster: (message: string, linkLiteral?: string, linkPath?: string) => void;
  closeToaster: (id: string) => void;
}

const Toaster: React.FC = () => {
  const appContext = useAppContext();
  const toasterContents = appContext.toaster.content;

  return (
    <>
      {toasterContents
        ? toasterContents.map((toasterContent) => (
            <ToasterItem key={toasterContent.id} toasterContent={toasterContent} />
          ))
        : ''}
    </>
  );
};

export default Toaster;
