import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Logo from 'components/logo/Logo';
import { useIntl } from 'react-intl';
import { translate } from 'common/utils/global.utils';
import { useHistory, useParams } from 'react-router';
import AccountResetComponent from 'components/account/AccountResetComponent';
import { useAppContext } from 'common/contexts/AppContext';

const ResetPasswordPage: React.FC = () => {
  const param = useParams<{ verificationId: string; verificationCode: string }>();
  const intl = useIntl();
  const appContext = useAppContext();
  const history = useHistory();
  const verificationId = param.verificationId;
  const verificationCode = param.verificationCode;
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const onResetPasswordSubmit = async (
    password: string,
    confirmPassword: string,
    setLoadingState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (password.length >= 6) {
      if (password === confirmPassword) {
        const resetPasswordResponse = await appContext.accountService.resetPassword(
          verificationId,
          verificationCode,
          password,
        );
        if (resetPasswordResponse) {
          if (resetPasswordResponse && appContext.firebaseAuth) {
            appContext.firebaseAuth.signInWithCustomToken(resetPasswordResponse.result).then((signInResponse) => {
              if (signInResponse.user) {
                appContext.toaster.pushSuccessToaster(`Welcome back ${signInResponse.user.displayName}`);
                history.push('/');
              } else {
                appContext.toaster.pushErrorToaster('Reset password failed!');
                setLoadingState(false);
              }
            });
          }
        }
      } else {
        appContext.toaster.pushErrorToaster('Confirmed Password did not match!');
        setLoadingState(false);
      }
    } else {
      appContext.toaster.pushErrorToaster('Minimal length of password is 6 characters');
      setLoadingState(false);
    }
  };
  return (
    <>
      <AccountResetComponent
        logo={Logo}
        title={translate(intl, 'RESET_PASSWORD_LINK')}
        username="username"
        actions={{ onResetPasswordSubmit }}
      />
    </>
  );
};

export default ResetPasswordPage;
