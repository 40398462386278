import React from 'react';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { Transition } from '@headlessui/react';
import { convertDateFromString, convertIDRFormat, getPriceChangesProperty, translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import ChangesLabelComponent from './ChangesLabelComponent';
import { FactoryType } from 'common/interfaces/factory.interface';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { MapMarker } from 'components/icon/IconsElement';

interface FactoryCardComponentType {
  factory: FactoryType;
  action?: RoutesType;
}

const FactoryCardComponent: React.FC<FactoryCardComponentType> = observer(
  ({ factory, action }: FactoryCardComponentType) => {
    const lastPrices = factory.chartPayload.data.slice(factory.chartPayload.data.length - 2);
    let changesProp;
    if (lastPrices.length > 1) {
      changesProp = getPriceChangesProperty(lastPrices[1].value, lastPrices[0].value);
    } else {
      if (lastPrices.length > 0) {
        changesProp = getPriceChangesProperty(lastPrices[0].value, 0);
      } else {
        changesProp = getPriceChangesProperty(0, 0);
      }
    }
    const intl = useIntl();
    const history = useHistory();
    let factoryLocation = factory.location;
    if (!history.location.pathname.includes('detail')) {
      factoryLocation = `${factory.location.substr(0, 32)}...`;
    }
    return (
      <li className="mb-10 shadow-lg">
        <article className="relative px-4 bg-white shadow p-6 h-full">
          <div className="flex">
            <div className="flex-grow pr-5">
              <h2 className="text-xl font-semibold text-gray-900">{factory.displayName}</h2>
            </div>
          </div>
          <div className="pr-5 pb-5 border-b-2">
            <p className="mt-2 text-sm text-gray-700 space-y-4">
              {MapMarker}
              {factoryLocation}
            </p>
          </div>
          <div className="flex pt-5">
            <div className="flex-grow pt-3">
              <div className="text-xs font-medium text-gray-500">Prices:</div>
              <div className="mt-1 text-xl inline-flex items-center text-sm font-medium rounded-md font-semibold">
                {convertIDRFormat(factory.price)}
              </div>

              <Transition show={factory.isPPN}>
                <div className="flex">
                  <span className="inline-flex text-sm">
                    <span className="inline-flex text-xs text-gray-500 font-medium">
                      {translate(intl, 'FACTORY_CARD_PPN', 'price inc 10% PPN')}
                    </span>
                  </span>
                </div>
              </Transition>
            </div>
            <div className="flex-grow">
              <ChangesLabelComponent
                label={changesProp.changesLabel}
                bgClassName={changesProp.bgClassName}
                fontColor={changesProp.fontColor}
              />
            </div>
          </div>
          <div className="mt-4 mb-20 flex justify-between">
            <div className="flex space-x-6">
              <span className="inline-flex text-xs text-gray-500 font-medium">
                {convertDateFromString(factory.prices[0].timestamp, 'MMMM DD, YYYY HH:mm:ss')}
              </span>
            </div>
          </div>
          {action && (
            <div className="mt-10 flex">
              <a
                href={action.link}
                className="bg-sss-200 w-11/12 mx-auto rounded-md text-center py-3 absolute bottom-5"
              >
                <span className="px-4 py-4">
                  <span className="text-sm font-medium text-white">{action.label}</span>
                </span>
              </a>
            </div>
          )}
        </article>
      </li>
    );
  },
);

export default FactoryCardComponent;
