import React from 'react';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useHistory } from 'react-router';

export enum NavigationPosition {
  TOP = 'TOP',
  SIDE = 'SIDE',
}

export interface SimpleNavigationComponentType {
  actions: RoutesType[];
  position: NavigationPosition;
}

const SimpleNagivationComponent: React.FC<SimpleNavigationComponentType> = ({
  actions,
  position,
}: SimpleNavigationComponentType) => {
  const history = useHistory();

  if (position === NavigationPosition.SIDE) {
    return (
      <div>
        {actions.map((navigation, id) => {
          let navigationClass = '';
          if (navigation.isActive) {
            navigationClass =
              'block px-4 py-2 mt-2 text-sm text-gray-900 bg-sss-100 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-sss-100 focus:bg-sss-100 focus:outline-none focus:shadow-outline';
          } else {
            navigationClass =
              'block px-4 py-2 mt-2 text-sm text-gray-900 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-sss-100 focus:bg-sss-100 focus:outline-none focus:shadow-outline';
          }
          return (
            <a
              href="#"
              onClick={() => {
                history.push(navigation.link!);
              }}
              key={id}
              className={navigationClass}
            >
              {navigation.icon}
              {navigation.label}
            </a>
          );
        })}
      </div>
    );
  }

  return (
    <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
      {actions.map((navigation, id) => {
        let navigationClass = '';
        if (navigation.isActive) {
          navigationClass =
            'border-sss-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
        } else {
          navigationClass =
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
        }
        return (
          <a
            href="#"
            onClick={() => {
              history.push(navigation.link!);
            }}
            key={id}
            className={navigationClass}
          >
            {navigation.label}
          </a>
        );
      })}
    </div>
  );
};

export default SimpleNagivationComponent;
