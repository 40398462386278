import React, { useEffect, useState } from 'react';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { LogoProperty, LogoVariantType } from 'components/logo/Logo';
import { MobileMenu } from 'components/icon/IconsElement';
import { Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'common/contexts/AppContext';
import { UserIcon } from 'components/icon/IconsElement';

export interface PublicLayoutSlotsType {
  main: React.ReactElement;
  secondary: React.ReactElement;
  content: React.ReactNode;
}

interface PublicLayoutType {
  logo: React.FC<LogoProperty>;
  slots: PublicLayoutSlotsType;
  searchPlaceholder: string;
  actions: RoutesType[];
}

const PublicLayout: React.FC<PublicLayoutType> = ({ logo, slots, searchPlaceholder, actions }: PublicLayoutType) => {
  const Logo = logo;
  const history = useHistory();
  const appContext = useAppContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const logoVariant = LogoVariantType.SMALL;
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  useEffect(() => {
    if (appContext.user.photoUrl) {
      setProfileImageUrl(appContext.user.photoUrl);
    }
  }, [appContext.user.photoUrl]);
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="md:flex md:flex-row md:min-h-screen w-full">
        <div className="hidden flex flex-col w-full lg:block md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">
          <div className="px-8 py-4 flex flex-row mx-auto">
            <a
              href="#"
              className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
            >
              <div className="flex px-2 lg:px-0">
                <Logo variant={logoVariant} link="/" />
                <span className="pt-6 pl-2 text-3xl">BBI</span>
              </div>
            </a>
            <button className="rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"></path>
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </div>
          <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">{slots.main}</nav>
        </div>
        <div className="flex items-right lg:hidden" onClick={() => setIsOpen(!isOpen)}>
          <button
            type="button"
            className="inline-flex p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sss-500"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            {MobileMenu}
          </button>
        </div>
        <Transition show={isOpen}>
          <div
            className="z-10 origin-top-right absolute right-0 mt-16 w-full rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <div className="pt-2 pb-3 space-y-1">
              {actions.map((navigation, id) => {
                let navigationClass = '';
                if (navigation.isActive) {
                  navigationClass =
                    'bg-sss-50 border-sss-500 text-sss-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium';
                } else {
                  navigationClass =
                    'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium';
                }
                return (
                  <a
                    href="#"
                    onClick={() => {
                      history.push(navigation.link!);
                    }}
                    key={id}
                    className={navigationClass}
                  >
                    {navigation.label}
                  </a>
                );
              })}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0" onClick={() => history.push('/settings')}>
                  {profileImageUrl ? <img className="h-10 w-10 rounded-md" src={profileImageUrl} alt="" /> : UserIcon}
                </div>
                <div className="ml-3" onClick={() => history.push('/settings')}>
                  <div className="text-base font-medium text-gray-800">{appContext.user.displayName}</div>
                  <div className="text-sm font-medium text-gray-500">{appContext.user.email}</div>
                </div>
                {/* <button className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500">
                      <span className="sr-only">View notifications</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                    </button> */}
              </div>
              <div className="mt-3 space-y-1">
                <a
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={() => {
                    if (appContext.firebaseAuth) {
                      appContext.firebaseAuth.signOut();
                      history.push('/home');
                    }
                  }}
                >
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </Transition>
        {slots.content}
      </div>
    </div>
  );
};

export default PublicLayout;
