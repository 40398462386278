import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { LogoProperty } from 'components/logo/Logo';
import { FormattedMessage } from 'react-intl';
import { UsernameType } from 'common/interfaces/account.interface';
import { UseFormRegisterReturn, ValidationMode } from 'react-hook-form';
import CountryCodeDropdown from 'components/dropdown/CountryCodeDropdown';

interface AccountUsernameProperty {
  Logo: React.FC<LogoProperty>; //Use UpperCase because it can be used as React Functional Component
  title: string;
  usernameTypes: UsernameType;
  allowedMobile: string[];
  actions?: {
    label?: string;
    route?: string;
    url?: string;
    setUsernameInputType: Dispatch<SetStateAction<UsernameType>>;
  };
  registerUsername: UseFormRegisterReturn;
  countryCode: string;
  setCountryCode: Dispatch<SetStateAction<string>>;
}

const AccountUsernameComponent: React.FC<AccountUsernameProperty> = ({
  Logo,
  title,
  usernameTypes,
  allowedMobile,
  actions,
  registerUsername,
  countryCode,
  setCountryCode,
}: AccountUsernameProperty) => {
  const [usernameTypeState, setUsernameTypesState] = useState<UsernameType>(usernameTypes);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (usernameTypeState === UsernameType.EMAIL) {
      setIsEmail(true);
    }
    if (usernameTypeState === UsernameType.MOBILE) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor="mobile_number" className="block text-sm font-medium text-gray-700">
          {title}
        </label>
        <Transition show={isMobile}>
          <a
            href="#"
            className="text-sm font-medium text-sss-500 hover:text-sss-300"
            id="email-optional"
            onClick={() => {
              if (actions) {
                setUsernameTypesState(UsernameType.EMAIL);
                actions.setUsernameInputType(UsernameType.EMAIL);
                setIsMobile(false);
                setIsEmail(true);
              }
            }}
          >
            <FormattedMessage id="usernameAccountSwitchToEmail" defaultMessage="Use email address" />
          </a>
        </Transition>
        <Transition show={isEmail}>
          <a
            href="#"
            className="text-sm font-medium text-sss-500 hover:text-sss-300"
            id="mobile-optional"
            onClick={() => {
              setUsernameTypesState(UsernameType.MOBILE);
              if (actions) {
                actions.setUsernameInputType(UsernameType.MOBILE);
                setIsEmail(false);
                setIsMobile(true);
              }
            }}
          >
            <FormattedMessage id="usernameAccountSwitchToMobilePhone" defaultMessage="Use mobile phone" />
          </a>
        </Transition>
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        {usernameTypeState === UsernameType.EMAIL && (
          <input
            type="text"
            id="username"
            required={true}
            {...registerUsername}
            className="focus:ring-sss-500 focus:border-sss-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="your@email.com"
          />
        )}
        {usernameTypeState === UsernameType.MOBILE && (
          <>
            <div className="absolute inset-y-0 left-0 flex items-center">
              <label htmlFor="country" className="sr-only">
                Country
              </label>
              <div className="relative inline-block text-left">
                <CountryCodeDropdown
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  allowedMobile={allowedMobile}
                />
              </div>
            </div>
            <input
              type="text"
              id="username"
              required={true}
              {...registerUsername}
              className="focus:ring-sss-500 focus:border-sss-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
              placeholder="8123456789"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountUsernameComponent;
