import { Time } from 'lightweight-charts';

export enum ChartType {
  CANDLE = '1',
  LINE = '2',
}

export interface ChartDataType {
  time: string;
  open: number;
  high: number;
  low: number;
  close: number;
  value: number;
}
