import React from 'react';
import ViewArrowComponent from 'components/icon/ViewArrowComponent';
import { convertIDRFormat, numberFormat, translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import { ContractListItemStatus, ContractListItemType } from 'common/interfaces/contract.interface';
import { useHistory } from 'react-router-dom';

const ContractListItemComponent: React.FC<ContractListItemType> = ({
  price,
  timestamp,
  quantity,
  quantityUnit,
  status,
  action,
}: ContractListItemType) => {
  const intl = useIntl();
  const history = useHistory();
  if (history.location.pathname.includes('contract')) {
    action.link = '#';
  }
  return (
    <li>
      <a href={action.link} className="block hover:bg-gray-50">
        <div className="px-4 py-4 flex items-center sm:px-6">
          <div className="min-w-0 flex-1 flex justify-between">
            <div className="truncate">
              <div className="lg:flex">
                <div
                  className={`text-xl font-semibold ${
                    status === ContractListItemStatus.CANCELLED && 'text-red-600 line-through'
                  }`}
                >
                  {convertIDRFormat(price)}
                </div>
                {status === ContractListItemStatus.CANCELLED && (
                  <div className="ml-0 lg:ml-3 inline-flex items-center text-xs font-medium bg-red-600 px-2 py-1 rounded-md text-white">
                    {translate(intl, 'CANCELLED')}
                  </div>
                )}
              </div>
              <div className="mt-2">
                <span className="inline-flex text-xs text-gray-500 font-medium">{timestamp}</span>
              </div>
            </div>
            <div className="flex-shrink-0 sm:mt-0 sm:ml-5">
              <div className="flex flex-col text-right">
                <div className="text-xs font-medium text-gray-500"> {translate(intl, 'QUANTITY')}: </div>
                <div
                  className={`mt-1 inline-flex items-center text-sm font-medium ${
                    status === ContractListItemStatus.CANCELLED ? 'bg-red-600' : 'bg-green-600'
                  } px-2 py-1 rounded-md text-white`}
                >
                  {numberFormat(quantity.toString())} {quantityUnit}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            <ViewArrowComponent />
          </div>
        </div>
      </a>
    </li>
  );
};

export default ContractListItemComponent;
