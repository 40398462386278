import { Transition } from '@headlessui/react';
import { useAppContext } from 'common/contexts/AppContext';
import { translate } from 'common/utils/global.utils';
import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

export enum DropdownMenuPosition {
  LEFT = 'LEFT',
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
}

interface LanguageDropdownType {
  choosenLanguage: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  position: DropdownMenuPosition;
}

const LanguageDropdown: React.FC<LanguageDropdownType> = ({
  choosenLanguage,
  isOpen,
  setIsOpen,
  position,
}: LanguageDropdownType) => {
  const appContext = useAppContext();
  const intl = useIntl();
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="inline-flex text-left w-auto mt-2 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {choosenLanguage}
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </button>

      <Transition show={isOpen}>
        {position === DropdownMenuPosition.RIGHT && (
          <div
            className="absolute z-50 mx-auto mt-1 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div className="py-1" role="none">
              <div
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                id="menu-item-0"
                onClick={() => {
                  setIsOpen(false);
                  appContext.setLanguage('en-US');
                }}
              >
                {translate(intl, 'ENGLISH')}
              </div>
              <div
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                id="menu-item-0"
                onClick={() => {
                  setIsOpen(false);
                  appContext.setLanguage('id');
                }}
              >
                {translate(intl, 'INDONESIA')}
              </div>
            </div>
          </div>
        )}
        {position === DropdownMenuPosition.MIDDLE && (
          <div
            className="absolute z-50 right-20 mt-8 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div className="py-1" role="none">
              <div
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                id="menu-item-0"
                onClick={() => {
                  setIsOpen(false);
                  appContext.setLanguage('en-US');
                }}
              >
                {translate(intl, 'ENGLISH')}
              </div>
              <div
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                id="menu-item-0"
                onClick={() => {
                  setIsOpen(false);
                  appContext.setLanguage('id');
                }}
              >
                {translate(intl, 'INDONESIA')}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};

export default LanguageDropdown;
