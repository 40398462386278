import { action, makeObservable, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { UserType } from 'common/interfaces/account.interface';

export class UserStore {
  userList: UserType = {};

  constructor() {
    makeObservable(this, {
      userList: observable,
      setUserList: action,
      getUserList: action,
    });
  }

  getUserList() {
    return this.userList;
  }

  setUserList(
    firestore: firebase.firestore.Firestore,
    userAuth: UserType,
    setUser: React.Dispatch<React.SetStateAction<UserType>>,
    setLanguage: (newLocale: string) => void,
  ) {
    const getUserData = firestore.doc(`users/${userAuth.uid}`).onSnapshot({ includeMetadataChanges: true }, (user) => {
      const userData = user.data();
      if (userData) {
        const displayNameArray: string[] = userData.displayName.split(' ');
        const firstname = displayNameArray[0];
        const lastname = displayNameArray[displayNameArray.length - 1];
        const phoneData = userData.phoneNumber;
        const countryCode = phoneData.substring(0, 3);
        const phoneNumberArray = phoneData.split(countryCode);
        const phoneNumber = phoneNumberArray[1];
        const photoUrl = userData.photoUrl;
        const userObject = {
          uid: user.id,
          displayName: userData.displayName,
          language: userData.language,
          email: userData.email,
          factoryIds: userData.factoryIds,
          firstname,
          lastname,
          countryCode,
          phoneNumber,
          photoUrl,
          pushNotifications: userData.pushNotifications!,
          desktopNotifications: userData.desktopNotifications!,
          priceUpdateNotifications: userData.priceUpdateNotifications!,
        };
        if (userObject) {
          setUser(userObject);
          setLanguage(userObject.language);
        }
      }
    });
    return () => getUserData();
  }
}

export const userStore = new UserStore();
