import { RoutesType } from 'common/interfaces/navigation.interface';
import React from 'react';

interface FooterComponentType {
  copyrightIcon: React.ReactElement;
  description: string;
  actions: RoutesType[];
}

const FooterComponent: React.FC<FooterComponentType> = ({
  copyrightIcon,
  description,
  actions,
}: FooterComponentType) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {actions.map((action, id) => (
            <a key={id} href={action.link} className="text-gray-400 hover:text-gray-500">
              {action.icon}
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">
            <span className="inline">
              {copyrightIcon} {description}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
