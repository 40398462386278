import React, { createRef, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LogoProperty } from 'components/logo/Logo';
import { FormattedMessage } from 'react-intl';
import AccountLayout from './AccountLayout';
import Countdown from 'react-countdown';
import { useForm } from 'react-hook-form';
import LoadingSpinner from 'components/animation/LoadingSpinner';

interface OtpFormType {
  otpCode: string;
}

interface AccountOtpProperty {
  logo: React.FC<LogoProperty>;
  title: string;
  username: string;
  actions?: {
    label?: string;
    route?: string;
    url?: string;
    onOtpSubmit: (code: string, setLoadingState: Dispatch<SetStateAction<boolean>>) => void;
    onOtpResend: () => void;
  };
}

interface CountdownRendererType {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

interface ResendOTPButtonType {
  countSecond: number;
}

const AccountOtpComponent: React.FC<AccountOtpProperty> = ({ logo, title, username, actions }: AccountOtpProperty) => {
  const inputRef = createRef<HTMLInputElement>();
  const buttonRef = createRef<HTMLButtonElement>();

  const [countdownSecond, setCountdownSecond] = useState(0);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<OtpFormType>();
  const OTP_COUNTDOWN_SECOND: number = 60;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputRef.current && buttonRef.current) {
        inputRef.current.blur();
        buttonRef.current.click();
      }
    }
  };
  useEffect(() => {
    if (countdownSecond > 0) {
      setTimeout(() => setCountdownSecond(countdownSecond - 1), 1000);
    }
  }, [countdownSecond]);

  const ResendOtpButton = () => {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          if (actions) {
            actions.onOtpResend();
            setCountdownSecond(OTP_COUNTDOWN_SECOND);
          }
        }}
        className="-ml-px cursor-pointer relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-sss-500 focus:border-sss-500"
      >
        <svg
          className="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
        </svg>
        <span>
          <FormattedMessage id="resend" defaultMessage="Resend" />
        </span>
      </div>
    );
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }: CountdownRendererType) => {
    let minutes_string = minutes.toString();
    let seconds_string = seconds.toString();
    if (completed) {
      return <></>;
    } else {
      if (minutes < 10) minutes_string = '0' + minutes_string;
      if (seconds < 10) seconds_string = '0' + seconds_string;
      return (
        <span className="pl-5 pt-1 w-16">
          {minutes_string}:{seconds_string}
        </span>
      );
    }
  };

  return (
    <AccountLayout logo={logo} title={title}>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            autoComplete="off"
            onSubmit={handleSubmit((otpFormData) => {
              setLoadingState(true);
              if (actions) {
                actions.onOtpSubmit(otpFormData.otpCode, setLoadingState);
              }
            })}
          >
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                <FormattedMessage id="otpCode" defaultMessage="OTP code" />
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                  </div>
                  <input
                    type="number"
                    id="otp"
                    required={true}
                    {...register('otpCode', { required: true })}
                    ref={inputRef}
                    className="focus:ring-sss-500 focus:border-sss-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                    placeholder="1234"
                    onKeyDown={(event) => handleKeyDown(event)}
                  />
                </div>
                {countdownSecond > 0 ? (
                  <Countdown
                    date={Date.now() + countdownSecond * 1000}
                    renderer={countdownRenderer}
                    onComplete={() => setCountdownSecond(0)}
                  />
                ) : (
                  <ResendOtpButton />
                )}
              </div>
              <p className="mt-2 text-sm text-gray-500" id="otp-description">
                <FormattedMessage
                  id="accountOtpComponentDescription"
                  defaultMessage="OTP code just sent to your mobile or email address in order to confirm your identity."
                />
              </p>
            </div>
            <div>
              <button
                type="submit"
                ref={buttonRef}
                disabled={loadingState}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sss-300 hover:bg-sss-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500"
              >
                {loadingState ? <LoadingSpinner /> : ''}
                <FormattedMessage id="confirmOtpButton" defaultMessage="Confirm OTP code" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default AccountOtpComponent;
