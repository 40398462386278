import React, { useEffect, useState } from 'react';
import { useAppContext } from 'common/contexts/AppContext';
import { translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import LoadingSpinner from 'components/animation/LoadingSpinner';
import LanguageDropdown, { DropdownMenuPosition } from 'components/dropdown/LanguageDropdown';
import { UserIcon } from 'components/icon/IconsElement';

const SettingsPreferenceComponent: React.FC = () => {
  const appContext = useAppContext();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [choosenLanguage, setChoosenLanguage] = useState<string>('');
  const intl = useIntl();
  useEffect(() => {
    if (appContext.locale) {
      if (appContext.locale === 'en-US') {
        setChoosenLanguage(translate(intl, 'ENGLISH'));
      } else {
        setChoosenLanguage(translate(intl, 'INDONESIA'));
      }
    }
  }, [appContext.locale]);
  const [avatarUrl, setAvatarUrl] = useState<string>('');

  useEffect(() => {
    if (appContext.user.photoUrl) {
      setAvatarUrl(appContext.user.photoUrl);
    }
  }, [appContext.user.photoUrl]);

  const updateProfilePicture = async (file?: File) => {
    if (file) {
      if (appContext.storage) {
        const uploadImage = appContext.storage.ref(`users/${appContext.user.uid}/profile.png`).put(file);
        if (uploadImage) {
          uploadImage.on(
            'state_changed',
            (snapshot) => {},
            (error) => {
              appContext.toaster.pushErrorToaster(error.message);
            },
            () => {
              if (appContext.storage) {
                appContext.storage
                  .ref(`users/${appContext.user.uid}`)
                  .child('profile.png')
                  .getDownloadURL()
                  .then(async (url) => {
                    if (appContext.firestore) {
                      await appContext.firestore.doc(`users/${appContext.user.uid}`).update({
                        photoUrl: url,
                      });
                    }
                  });
              }
            },
          );
        }
      }
    } else {
      if (appContext.firestore) {
        await appContext.firestore.doc(`users/${appContext.user.uid}`).update({
          photoUrl: '',
        });
      }
    }
  };

  const handleFileChange = (files: FileList) => {
    setLoadingState(true);
    if (files.length) {
      setAvatarUrl(URL.createObjectURL(files[0]));
      updateProfilePicture(files[0]);
    }
    appContext.toaster.pushSuccessToaster('Success updating your profile picture');
    setLoadingState(false);
  };
  return (
    <section id="preference-setting">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                {translate(intl, 'PREFERENCE_SETTING_TITLE')}
              </h2>
              <p className="mt-1 text-sm text-gray-500">{translate(intl, 'PREFERENCE_SETTING_DESCRIPTION')}</p>
            </div>
            <div className="mt-6 grid grid-cols-4 gap-6">
              <div className="sm:col-span-6">
                <label className="block text-sm font-medium text-gray-700">{translate(intl, 'PHOTO')}</label>
                <div className="mt-1 flex items-center">
                  {avatarUrl ? (
                    <img className="inline-block h-12 w-12 rounded-full" src={avatarUrl} alt="" />
                  ) : (
                    <div className="inline-block">{UserIcon}</div>
                  )}
                  <div className="ml-4 flex">
                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                      <label
                        htmlFor="user_photo"
                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                      >
                        <span>{translate(intl, 'CHANGE')}</span>
                        <span className="sr-only"> {translate(intl, 'USER_PHOTO')}</span>
                      </label>
                      <input
                        id="user_photo"
                        name="user_photo"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={(element) => handleFileChange(element.target.files!)}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                    {avatarUrl && (
                      <button
                        type="button"
                        className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                        onClick={() => {
                          setLoadingState(true);
                          setAvatarUrl('');
                          updateProfilePicture();
                          setLoadingState(false);
                          appContext.toaster.pushSuccessToaster('Profile picture removed');
                        }}
                      >
                        {translate(intl, 'REMOVE')}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label htmlFor="language" className="block text-sm font-medium text-gray-700">
                  {translate(intl, 'DEFAULT_LANGUAGE')}
                </label>
                <LanguageDropdown
                  choosenLanguage={choosenLanguage}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  position={DropdownMenuPosition.RIGHT}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              disabled={loadingState}
              className="bg-sss-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              onClick={async (event) => {
                setLoadingState(true);
                event.preventDefault();
                if (appContext.firestore) {
                  await appContext.firestore.doc(`users/${appContext.user.uid}`).update({
                    language: appContext.locale,
                  });
                  setLoadingState(false);
                  appContext.toaster.pushSuccessToaster('Success updating your preference settings');
                }
              }}
            >
              {loadingState ? <LoadingSpinner /> : ''}
              {translate(intl, 'SAVE')}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SettingsPreferenceComponent;
