import React, { useEffect, useState } from 'react';
import PublicLayout from 'components/public/PublicLayout';
import Logo from 'components/logo/Logo';
import SimpleNagivationComponent, { NavigationPosition } from 'components/navigation/SimpleNagivationComponent';
import ProfileNavigationComponent from 'components/navigation/ProfileNavigationComponent';
import { privacyContent, privacyTitle } from 'common/content/PrivacyContent';
import ContentComponent from 'components/content/ContentComponent';
import FooterComponent from 'components/content/FooterComponent';
import { getCurrentYear, setNavigationActive } from 'common/utils/global.utils';
import { authenticatedActions, guestActions, iconsActions, simpleNavigationActionsProp } from 'common/data/global.data';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useAppContext } from 'common/contexts/AppContext';

const DashboardPage: React.FC = () => {
  const currentNavigations: RoutesType[] = setNavigationActive(simpleNavigationActionsProp, '/');
  const appContext = useAppContext();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const slots = {
    main: <SimpleNagivationComponent actions={currentNavigations} position={NavigationPosition.SIDE} />,
    secondary: (
      <ProfileNavigationComponent
        isAuthenticated={appContext.isAuthenticated}
        imageUrl=""
        authenticatedActions={authenticatedActions}
        guestActions={guestActions}
      />
    ),
    content: (
      <div className="py-1">
        <ContentComponent title={privacyTitle} content={privacyContent} />
        <FooterComponent
          copyrightIcon={<span>&copy;</span>}
          description={`${getCurrentYear()} BBI. All rights reserved.`}
          actions={iconsActions}
        />
      </div>
    ),
  };
  return (
    <div>
      <PublicLayout logo={Logo} slots={slots} actions={currentNavigations} searchPlaceholder="Search here" />
    </div>
  );
};

export default DashboardPage;
