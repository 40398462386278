import React, { useEffect, useState } from 'react';
import AccountSignInComponent from 'components/account/AccountSignInComponent';
import Logo from 'components/logo/Logo';
import { accountInputType } from 'common/interfaces/account.interface';
import { translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'common/contexts/AppContext';

const SignInPage: React.FC = () => {
  const appContext = useAppContext();
  const history = useHistory();
  const intl = useIntl();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const signIn = {
    label: 'Sign-in instead?',
    link: '/account/sign-in',
    onClick: async (accountInput: accountInputType) => {
      const signInResponse = await appContext.accountService.signIn(accountInput.username!, accountInput.password!);
      if (signInResponse) {
        if (signInResponse.result) {
          if (signInResponse.result.error) {
            accountInput.setLoadingState(false);
            appContext.toaster.pushErrorToaster(signInResponse.result.error.message);
          } else {
            accountInput.setLoadingState(false);
            appContext.toaster.pushSuccessToaster('OTP Sent!');
            if (accountInput.rememberMe) {
              localStorage.setItem('usernameRememberMe', accountInput.username!);
            } else {
              localStorage.removeItem('usernameRememberMe');
            }
            history.push(`/account/otp`, {
              username: accountInput.username,
              uid: signInResponse.result.verificationId,
            });
          }
        } else {
          accountInput.setLoadingState(false);
          appContext.toaster.pushErrorToaster(signInResponse.error.message);
        }
      }
    },
  };

  const forget = {
    label: 'Forget password',
    link: '/account/forget-password',
    onClick: () => {},
  };
  return (
    <>
      <AccountSignInComponent
        logo={Logo}
        title={translate(intl, 'accountSignInComponentTitle', 'Sign in to your account')}
        username="username"
        actions={{ signIn, forget }}
      />
    </>
  );
};

export default SignInPage;
