import { Dispatch, SetStateAction } from 'react';

export enum UsernameType {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
}

export interface UserType {
  uid?: string | null;
  displayName?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  pushNotifications?: boolean | null;
  desktopNotifications?: boolean | null;
  priceUpdateNotifications?: boolean | null;
  language?: string | null;
  factoryIds?: string[] | null;
  photoUrl?: string;
}

export interface accountInputType {
  username?: string;
  usernameType: UsernameType;
  password?: string;
  rememberMe?: boolean;
  setLoadingState: Dispatch<SetStateAction<boolean>>;
}

export interface RouteType {
  label?: string;
  link?: string;
  onClick: (accountInput: accountInputType) => void;
}

export interface RoutesType {
  signIn?: RouteType;
  forget?: RouteType;
}
