import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Provider from 'components/wrapper/Provider';
import SignInPage from 'pages/SignInPage';
import ForgetPasswordPage from 'pages/ForgetPasswordPage';
import OtpPage from 'pages/OtpPage';
import FactoryDetailPage from 'pages/FactoryDetailPage';
import FactoryListPage from 'pages/FactoryListPage';
import ContractListPage from 'pages/ContractListPage';
import PrivacyPage from 'pages/PrivacyPage';
import TermPage from 'pages/TermPage';
import DashboardPage from 'pages/DashboardPage';
import ContractDetailPage from 'pages/ContractDetailPage';
import SettingPage from 'pages/SettingPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import Toaster from './toaster/Toaster';
import HomePage from 'pages/HomePage';
import CreatePasswordPage from 'pages/CreatePasswordPage';

const App: React.FC = () => {
  return (
    <Router>
      <Provider>
        <Toaster />
        <Switch>
          <Route path="/home">
            <HomePage />
          </Route>
          <Route path="/account/otp">
            <OtpPage />
          </Route>
          <Route path="/account/sign-in">
            <SignInPage />
          </Route>
          <Route path="/account/forget-password">
            <ForgetPasswordPage />
          </Route>
          <Route path="/account/reset-password/:verificationId/:verificationCode">
            <ResetPasswordPage />
          </Route>
          <Route path="/links/:randomTokenFromFirebase/:customToken">
            <CreatePasswordPage />
          </Route>
          <Route path="/factory/detail/:factoryID">
            <FactoryDetailPage />
          </Route>
          <Route path="/factories">
            <FactoryListPage />
          </Route>
          <Route path="/contracts">
            <ContractListPage />
          </Route>
          <Route path="/contract/detail/:factoryID">
            <ContractDetailPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <Route exact path="/terms">
            <TermPage />
          </Route>
          <Route exact path="/settings">
            <SettingPage />
          </Route>
          <Route exact path="/">
            <DashboardPage />
          </Route>
        </Switch>
      </Provider>
    </Router>
  );
};

export default App;
