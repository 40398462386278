import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Logo from 'components/logo/Logo';
import { useIntl } from 'react-intl';
import { translate } from 'common/utils/global.utils';
import { useHistory, useParams } from 'react-router';
import { useAppContext } from 'common/contexts/AppContext';
import AccountResetComponent from 'components/account/AccountResetComponent';

const CreatePasswordPage: React.FC = () => {
  const param = useParams<{ customToken: string }>();
  const intl = useIntl();
  const appContext = useAppContext();
  const customToken = param.customToken;
  const history = useHistory();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const onCreatePasswordSubmit = async (
    password: string,
    confirmPassword: string,
    setLoadingState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (password.length >= 6) {
      if (password === confirmPassword) {
        appContext.firebaseAuth?.signInWithCustomToken(customToken).then(async (response) => {
          if (response.user) {
            const createPassword = await appContext.accountService.updatePassword(password, response.user.uid);
            if (createPassword.result) {
              appContext.toaster.pushSuccessToaster(
                `Hi, ${response.user.displayName} your password have succesfully updated`,
              );
              history.push('/');
            }
          }
        });
      } else {
        appContext.toaster.pushErrorToaster('Confirmed Password did not match!');
        setLoadingState(false);
      }
    } else {
      appContext.toaster.pushErrorToaster('Minimal length of password is 6 characters');
      setLoadingState(false);
    }
  };
  return (
    <>
      <AccountResetComponent
        logo={Logo}
        title={translate(intl, 'CREATE_PASSWORD_LINK')}
        username="username"
        actions={{ onCreatePasswordSubmit }}
      />
    </>
  );
};

export default CreatePasswordPage;
