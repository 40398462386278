import React from 'react';

interface ChangesLabelComponentType {
  label: string;
  bgClassName: string;
  fontColor?: string;
}

const ChangesLabelComponent: React.FC<ChangesLabelComponentType> = ({
  label,
  bgClassName,
  fontColor,
}: ChangesLabelComponentType) => {
  return (
    <div className="pt-4">
      <div className="text-xs font-medium text-gray-500">Changes:</div>
      <div className={`mt-1 inline-flex items-center text-lg font-semibold rounded-md ${fontColor}`}>{label}</div>
    </div>
  );
};

export default ChangesLabelComponent;
