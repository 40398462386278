import React, { useEffect, useState } from 'react';
import PublicLayout from 'components/public/PublicLayout';
import Logo from 'components/logo/Logo';
import SimpleNagivationComponent, { NavigationPosition } from 'components/navigation/SimpleNagivationComponent';
import ProfileNavigationComponent from 'components/navigation/ProfileNavigationComponent';
import FactoryCardComponent from 'components/factory/FactoryCardComponent';
import { useIntl } from 'react-intl';
import { setNavigationActive, translate } from 'common/utils/global.utils';
import { authenticatedActions, guestActions, simpleNavigationActionsProp } from 'common/data/global.data';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useAppContext } from 'common/contexts/AppContext';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import PagePlaceholder from 'components/placeholder/PagePlaceholder';

const ContractListPage: React.FC = () => {
  const intl = useIntl();
  const currentNavigations: RoutesType[] = setNavigationActive(simpleNavigationActionsProp, '/contracts');
  const appContext = useAppContext();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    if (appContext.factories.length) {
      setIsDoneLoading(true);
    } else {
      setTimeout(() => setIsDoneLoading(true), 3500);
    }
  }, []);
  const slots = {
    main: <SimpleNagivationComponent actions={currentNavigations} position={NavigationPosition.SIDE} />,
    secondary: (
      <ProfileNavigationComponent
        isAuthenticated={appContext.isAuthenticated}
        imageUrl=""
        authenticatedActions={authenticatedActions}
        guestActions={guestActions}
      />
    ),
    content: (
      <div className="py-6 lg:py-10 w-full">
        <div className="max-w">
          <main className="px-4">
            <div>
              <h1 className="text-lg leading-6 font-medium text-gray-900">{translate(intl, 'CONTRACTS')}</h1>
              <ul className="mt-4 gap-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {appContext.factories.map((factory, id) => {
                  const action = {
                    label: 'View Details',
                    link: `/contract/detail/${factory.id}`,
                  };
                  return <FactoryCardComponent key={id} factory={factory} action={action} />;
                })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    ),
  };
  return (
    <ReactPlaceholder ready={isDoneLoading && appContext.isAuthenticated} customPlaceholder={<PagePlaceholder />}>
      <PublicLayout logo={Logo} slots={slots} actions={currentNavigations} searchPlaceholder="Search here" />
    </ReactPlaceholder>
  );
};

export default ContractListPage;
