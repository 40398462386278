import { ChartDataType, ChartType } from './chart.interface';
import { RoutesType } from './navigation.interface';

export enum ContractListItemStatus {
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface ContractListItemType {
  id: string;
  price: number;
  timestamp: string;
  quantity: number;
  quantityUnit: string;
  status: ContractListItemStatus;
  action: RoutesType;
}

export interface ContractType {
  title: string;
  listLimit: number;
  contractListItem: ContractListItemType[];
}

export interface ContractHistoryPayloadType {
  id: string;
  chartType: ChartType;
  priceCurrency: string;
  data: ChartDataType[];
}
