import { translate } from 'common/utils/global.utils';
import { LogoProperty } from 'components/logo/Logo';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import AccountLayout from './AccountLayout';
import { useForm } from 'react-hook-form';
import LoadingSpinner from 'components/animation/LoadingSpinner';

interface ResetPasswordFormType {
  password: string;
  confirmPassword: string;
}

interface AccountResetType {
  logo: React.FC<LogoProperty>;
  title: string;
  username: string;
  actions?: {
    label?: string;
    route?: string;
    url?: string;
    onResetPasswordSubmit?: (
      password: string,
      confirmPassword: string,
      setLoadingState: Dispatch<SetStateAction<boolean>>,
    ) => void;
    onCreatePasswordSubmit?: (
      password: string,
      confirmPassword: string,
      setLoadingState: Dispatch<SetStateAction<boolean>>,
    ) => void;
  };
}

const AccountResetComponent: React.FC<AccountResetType> = ({ logo, title, username, actions }: AccountResetType) => {
  const intl = useIntl();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<ResetPasswordFormType>();

  return (
    <AccountLayout logo={logo} title={title}>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit((resetPasswordFormData) => {
              setLoadingState(true);
              if (actions) {
                if (actions.onResetPasswordSubmit) {
                  actions.onResetPasswordSubmit(
                    resetPasswordFormData.password,
                    resetPasswordFormData.confirmPassword,
                    setLoadingState,
                  );
                }
                if (actions.onCreatePasswordSubmit) {
                  actions.onCreatePasswordSubmit(
                    resetPasswordFormData.password,
                    resetPasswordFormData.confirmPassword,
                    setLoadingState,
                  );
                }
              }
            })}
          >
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {translate(intl, 'PASSWORD')}
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  required={true}
                  {...register('password', { required: true })}
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sss-500 focus:border-sss-500 sm:text-sm"
                  placeholder="Enter your new password"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirm" className="block text-sm font-medium text-gray-700">
                {translate(intl, 'CONFIRM_PASSWORD')}
              </label>
              <div className="mt-1">
                <input
                  id="confirm"
                  type="password"
                  required={true}
                  {...register('confirmPassword', { required: true })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sss-500 focus:border-sss-500 sm:text-sm"
                  placeholder="Confirm your new password"
                />
              </div>
            </div>

            <div className="pt-4">
              <button
                type="submit"
                disabled={loadingState}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sss-300 hover:bg-sss-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500"
              >
                {loadingState ? <LoadingSpinner /> : ''}
                {translate(intl, 'RESET_PASSWORD')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default AccountResetComponent;
