import React, { useEffect, useState } from 'react';
import PublicLayout from 'components/public/PublicLayout';
import Logo from 'components/logo/Logo';
import SimpleNagivationComponent, { NavigationPosition } from 'components/navigation/SimpleNagivationComponent';
import ProfileNavigationComponent from 'components/navigation/ProfileNavigationComponent';
import FactoryCardComponent from 'components/factory/FactoryCardComponent';
import CommodityCardComponent from 'components/commodity/CommodityCardComponent';
import { setNavigationActive, translate } from 'common/utils/global.utils';
import { authenticatedActions, guestActions, simpleNavigationActionsProp } from 'common/data/global.data';
import { useIntl } from 'react-intl';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useAppContext } from 'common/contexts/AppContext';
import { observer } from 'mobx-react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import PagePlaceholder from 'components/placeholder/PagePlaceholder';
import LanguageDropdown, { DropdownMenuPosition } from 'components/dropdown/LanguageDropdown';

const DashboardPage: React.FC = observer(() => {
  const appContext = useAppContext();
  const intl = useIntl();
  const currentNavigations: RoutesType[] = setNavigationActive(simpleNavigationActionsProp, '/');
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [choosenLanguage, setChoosenLanguage] = useState<string>('');
  useEffect(() => {
    if (appContext.factories.length) {
      setIsDoneLoading(true);
    } else {
      setTimeout(() => setIsDoneLoading(true), 3500);
    }
  }, []);
  useEffect(() => {
    if (appContext.locale) {
      if (appContext.locale === 'en-US') {
        setChoosenLanguage(translate(intl, 'ENGLISH'));
      } else {
        setChoosenLanguage(translate(intl, 'INDONESIA'));
      }
    }
  }, [appContext.locale]);
  const slots = {
    main: <SimpleNagivationComponent actions={currentNavigations} position={NavigationPosition.SIDE} />,
    secondary: (
      <ProfileNavigationComponent
        isAuthenticated={appContext.isAuthenticated}
        imageUrl=""
        authenticatedActions={authenticatedActions}
        guestActions={guestActions}
      />
    ),
    content: (
      <div className="py-6 lg:py-2">
        <div className="max-w">
          <main className="px-4">
            <div>
              <div className="hidden lg:flex justify-between h-16 mb-10">
                <div className="lg:flex items-center leading-6 text-3xl text-gray-900 mb-10 font-semibold pt-8">
                  {translate(intl, 'DASHBOARD')}
                </div>
                <div className="flex items-center">
                  {/* <LanguageDropdown
                    choosenLanguage={choosenLanguage}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    position={DropdownMenuPosition.MIDDLE}
                  /> */}
                  <ProfileNavigationComponent
                    isAuthenticated={appContext.isAuthenticated}
                    imageUrl=""
                    authenticatedActions={authenticatedActions}
                    guestActions={guestActions}
                  />
                </div>
              </div>

              <h1 className="text-lg leading-6 font-medium text-gray-900">{translate(intl, 'FACTORIES')}</h1>
              <ul className="mt-4 gap-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {appContext.factories.map((factory, id) => {
                  const action = {
                    label: 'View Details',
                    link: `/factory/detail/${factory.id}`,
                  };
                  return <FactoryCardComponent key={id} factory={factory} action={action} />;
                })}
              </ul>
            </div>

            <div className="mt-10">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{translate(intl, 'COMMODITY_PRICE')}</h3>
              <div className="mt-5 gap-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {appContext.commodities.map((commodity, id) => (
                  <CommodityCardComponent key={id} commodity={commodity} />
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>
    ),
  };
  return (
    <ReactPlaceholder ready={isDoneLoading && appContext.isAuthenticated} customPlaceholder={<PagePlaceholder />}>
      <PublicLayout logo={Logo} slots={slots} actions={currentNavigations} searchPlaceholder="Search here" />
    </ReactPlaceholder>
  );
});

export default DashboardPage;
