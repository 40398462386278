import React, { useEffect, useState } from 'react';
import PublicLayout from 'components/public/PublicLayout';
import Logo from 'components/logo/Logo';
import SimpleNagivationComponent, { NavigationPosition } from 'components/navigation/SimpleNagivationComponent';
import ProfileNavigationComponent from 'components/navigation/ProfileNavigationComponent';
import FactoryCardComponent from 'components/factory/FactoryCardComponent';
import TVChartComponent from 'components/chart/TVChartComponent';
import FactoryDetailSelectComponent from 'components/factory/FactoryDetailSelectComponent';
import { setNavigationActive, translate } from 'common/utils/global.utils';
import { useIntl } from 'react-intl';
import ContractListComponent from 'components/contract/ContractListComponent';
import { authenticatedActions, guestActions, simpleNavigationActionsProp } from 'common/data/global.data';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useAppContext } from 'common/contexts/AppContext';
import { useParams } from 'react-router';
import { ContractHistoryPayloadType, ContractListItemType } from 'common/interfaces/contract.interface';
import { contractStore } from 'common/stores/contract.store';
import { ChartType } from 'common/interfaces/chart.interface';
import { observer } from 'mobx-react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import PagePlaceholder from 'components/placeholder/PagePlaceholder';
import { FactoryType } from 'common/interfaces/factory.interface';

const FactoryDetailPage: React.FC = observer(() => {
  const intl = useIntl();
  const param = useParams<{ factoryID: string }>();
  const [factoryName, setFactoryName] = useState<string>('');
  const [factoryDetail, setFactoryDetail] = useState<FactoryType>();
  const currentNavigations: RoutesType[] = setNavigationActive(simpleNavigationActionsProp, '/factories');
  const appContext = useAppContext();

  useEffect(() => {
    if (factoryDetail) {
      setFactoryName(factoryDetail.displayName);
    }
  }, [factoryDetail]);

  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    if (appContext.factories.length) {
      setIsDoneLoading(true);
    } else {
      setTimeout(() => setIsDoneLoading(true), 3500);
    }
  }, []);

  const [contractList, setContractList] = useState<ContractListItemType[]>([]);
  const [contractHistoryPayload, setContractHistoryPayload] = useState<ContractHistoryPayloadType>({
    id: '',
    chartType: ChartType.LINE,
    priceCurrency: 'Rp',
    data: [],
  });

  useEffect(() => {
    setFactoryDetail(appContext.factories.filter((e) => e.id == param.factoryID)[0]);
    if (appContext.firestore && factoryDetail && factoryDetail.id) {
      setContractList([]);
      setContractHistoryPayload({ id: '', chartType: ChartType.LINE, priceCurrency: 'Rp', data: [] });
      contractStore.setContractList(
        appContext.firestore!,
        setContractList,
        setContractHistoryPayload,
        factoryDetail.id,
      );
    }
  }, [appContext, factoryDetail, param]);

  const contract = {
    title: translate(intl, 'CONTRACTS'),
    listLimit: 5,
    contractListItem: contractList,
  };

  const slots = {
    main: <SimpleNagivationComponent actions={currentNavigations} position={NavigationPosition.SIDE} />,
    secondary: (
      <ProfileNavigationComponent
        isAuthenticated={appContext.isAuthenticated}
        imageUrl=""
        authenticatedActions={authenticatedActions}
        guestActions={guestActions}
      />
    ),
    content: (
      <div className="py-6 lg:py-10 w-full">
        <div className="max-w">
          <main className="px-4">
            <div>
              <FactoryDetailSelectComponent
                factories={appContext.factories}
                factoryName={factoryName}
                selectType="factory"
              />
              <h3 className="px-2 mt-8 text-lg leading-6 font-medium text-gray-900">
                {translate(intl, 'FACTORY_DETAILS')}
              </h3>
              <ul className="mt-4">{factoryDetail && <FactoryCardComponent factory={factoryDetail} />}</ul>
            </div>
            <div>
              <h3 className="px-2 mt-8 text-lg leading-6 font-medium text-gray-900">
                {translate(intl, 'PRICE_HISTORY')}
              </h3>
              <div className="mt-4 p-4 bg-white shadow rounded-md h-400px">
                {factoryDetail && factoryDetail.chartPayload.data[0].close && (
                  <div key={factoryDetail.id} id={factoryDetail.chartPayload.id} className="h-full w-full min-h-360px">
                    <TVChartComponent payload={factoryDetail.chartPayload} />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-8 grid gap-4 grid-cols-1 md:grid-cols-2">
              {contractList && <ContractListComponent contract={contract} />}
              <div className="mt-4 md:mt-0">
                <h3 className="px-2 text-lg leading-6 font-medium text-gray-900">
                  {translate(intl, 'CONTRACT_HISTORY')}
                </h3>
                {factoryDetail && contractHistoryPayload.data[0] ? (
                  <div id={contractHistoryPayload.id} className="h-full w-full mt-4 shadow">
                    <TVChartComponent key={factoryDetail.id} payload={contractHistoryPayload} />
                  </div>
                ) : (
                  <div className="p-2 mb-10 text-gray-500">Data not found</div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    ),
  };
  return (
    <ReactPlaceholder ready={isDoneLoading && appContext.isAuthenticated} customPlaceholder={<PagePlaceholder />}>
      <PublicLayout logo={Logo} slots={slots} actions={currentNavigations} searchPlaceholder="Search here" />
    </ReactPlaceholder>
  );
});

export default FactoryDetailPage;
