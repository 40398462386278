import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { RoutesType } from 'common/interfaces/navigation.interface';
import { useAppContext } from 'common/contexts/AppContext';
import { useHistory } from 'react-router';
import { UserIcon } from 'components/icon/IconsElement';

export interface ProfileNavigationComponentType {
  isAuthenticated: boolean;
  imageUrl: string;
  authenticatedActions: {
    profile: RoutesType;
    setting: RoutesType;
    signout: RoutesType;
  };
  guestActions: {
    signin: RoutesType;
  };
}

const ProfileNavigationComponent: React.FC<ProfileNavigationComponentType> = ({
  isAuthenticated,
  imageUrl,
  authenticatedActions,
  guestActions,
}: ProfileNavigationComponentType) => {
  const [isOpen, setIsOpen] = useState(false);
  const appContext = useAppContext();
  const history = useHistory();
  const [profileImageUrl, setProfileImageUrl] = useState<string>(imageUrl);
  useEffect(() => {
    if (appContext.user.photoUrl) {
      setProfileImageUrl(appContext.user.photoUrl);
    } else {
      setProfileImageUrl(imageUrl);
    }
  }, [appContext.user.photoUrl]);

  return (
    <div className="ml-4 relative flex-shrink-0">
      <div>
        <button
          type="button"
          className="bg-white rounded-full flex text-sm focus:outline-none"
          id="user-menu"
          aria-expanded="false"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {profileImageUrl ? <img className="h-8 w-8 rounded-md" src={profileImageUrl} alt="" /> : UserIcon}
        </button>
      </div>
      <Transition show={isOpen}>
        <div
          className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {isAuthenticated ? (
            <>
              <a
                href={authenticatedActions.profile.link}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                {authenticatedActions.profile.label}
              </a>
              <a
                href={authenticatedActions.setting.link}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                {authenticatedActions.setting.label}
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                onClick={() => {
                  if (appContext.firebaseAuth) {
                    appContext.firebaseAuth.signOut();
                    history.push('/home');
                  }
                }}
              >
                {authenticatedActions.signout.label}
              </a>
            </>
          ) : (
            <a
              href={guestActions.signin.link}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              {guestActions.signin.label}
            </a>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default ProfileNavigationComponent;
