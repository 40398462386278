import { FactoryPriceType, FactoryType } from 'common/interfaces/factory.interface';
import { action, makeObservable, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ChartDataType, ChartType } from 'common/interfaces/chart.interface';
import { convertDateFromString, filterChartDataWithSameTimePropertyValue } from 'common/utils/global.utils';

export class FactoryStore {
  factoryList: FactoryType[] = [];

  constructor() {
    makeObservable(this, {
      factoryList: observable,
      insertFactory: action,
      setFactoryList: action,
      getFactoryList: action,
      removeFactory: action,
    });
  }

  getFactoryList() {
    return this.factoryList;
  }

  setFactoryList(
    firestore: firebase.firestore.Firestore,
    setFactories: React.Dispatch<React.SetStateAction<FactoryType[]>>,
    factoryId: string,
  ) {
    const getFactory = firestore.doc(`factories/${factoryId}`).onSnapshot((factory) => {
      const factoryData = factory.data();
      if (factoryData) {
        if (factoryData.prices) {
          const priceList: FactoryPriceType[] = [];
          factoryData.prices.map((price: FactoryPriceType) => {
            if (price.prices) {
              price.prices.map((priceDetail) => {
                const priceObject = {
                  id: price.id,
                  commodity: factoryData.commodity,
                  price: priceDetail.prc,
                  timestamp: priceDetail.ts,
                };
                priceList.push(priceObject);
              });
            }
          });
          let factoryPriceHistoryChartData: ChartDataType[] = [];
          priceList.map((price) => {
            factoryPriceHistoryChartData.push({
              time: convertDateFromString(price.timestamp),
              value: price.price,
              open: price.price,
              high: price.price,
              low: price.price,
              close: price.price,
            });
          });

          //Filter chart data with same time value.
          factoryPriceHistoryChartData = filterChartDataWithSameTimePropertyValue(
            factoryPriceHistoryChartData,
            true,
          ).reverse();

          if (factoryData) {
            const factoryObject = {
              id: factory.id,
              displayName: factoryData.displayName,
              commodity: factoryData.commodity,
              price: factoryData.price,
              code: factoryData.code,
              location: factoryData.location,
              prices: priceList,
              chartPayload: {
                id: `factory-price-history-chart-${factory.id}${Math.random()}`,
                chartType: ChartType.LINE,
                priceCurrency: 'Rp',
                data: factoryPriceHistoryChartData,
              },
              isPPN: true,
            };
            // Remove duplicate array if update happened
            this.removeFactory(factory.id);
            this.insertFactory(factoryObject);
            setFactories(this.factoryList);
          }
        }
      }
    });
    return () => getFactory();
  }

  insertFactory(factoryList: FactoryType) {
    this.factoryList.push(factoryList);
  }

  removeFactory(deleted_id: string) {
    const updatedfactoryList = this.factoryList.filter((e) => e.id != deleted_id);
    this.factoryList = updatedfactoryList;
  }
}

export const factoryStore = new FactoryStore();
