import { useAppContext } from 'common/contexts/AppContext';
import { translate } from 'common/utils/global.utils';
import LoadingSpinner from 'components/animation/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const SettingsNotificationComponent: React.FC = () => {
  const appContext = useAppContext();
  const [pushNotificationSetting, setPushNotificationSetting] = useState<boolean>(false);
  const [desktopNotificationSetting, setDesktopNotificationSetting] = useState<boolean>(false);
  const [priceUpdateNotificationSetting, setPriceUpdateNotificationSetting] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const notificationButtonSettings = (isActive: boolean) => {
    // Init with inactive class
    const notificationButtonSettingsReturn = {
      buttonClass: ' bg-gray-200 ',
      innerButtonClass: ' translate-x-0 ',
    };
    if (isActive) {
      notificationButtonSettingsReturn.buttonClass = ' bg-green-500 ';
      notificationButtonSettingsReturn.innerButtonClass = ' translate-x-5 ';
    }
    return notificationButtonSettingsReturn;
  };

  const pushNotificationButtonSetting = notificationButtonSettings(pushNotificationSetting);
  const desktopNotificationButtonSetting = notificationButtonSettings(desktopNotificationSetting);
  const priceUpdateNotificationButtonSetting = notificationButtonSettings(priceUpdateNotificationSetting);

  useEffect(() => {
    setPushNotificationSetting(appContext.user.pushNotifications!);
    setDesktopNotificationSetting(appContext.user.desktopNotifications!);
    setPriceUpdateNotificationSetting(appContext.user.priceUpdateNotifications!);
  }, [appContext.user]);

  const intl = useIntl();
  return (
    <section id="notification-setting">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                {translate(intl, 'NOTIFICATION_SETTING_TITLE')}
              </h2>
              <p className="mt-1 text-sm text-gray-500">{translate(intl, 'NOTIFICATION_SETTING_DESCRIPTION')}</p>
            </div>

            <ul className="mt-2 divide-y divide-gray-100">
              <li className="py-4 flex items-center justify-between" x-data="{ on: true }">
                <div className="flex flex-col">
                  <p className="text-sm font-medium text-gray-900" id="privacy-option-1-label">
                    {translate(intl, 'ENABLE_MOBILE_PUSH')}
                  </p>
                  <p className="text-sm text-gray-500" id="privacy-option-1-description">
                    {translate(intl, 'ENABLE_MOBILE_PUSH_DESCRIPTION')}
                  </p>
                </div>
                <button
                  type="button"
                  className={`ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${pushNotificationButtonSetting.buttonClass}`}
                  onClick={() => setPushNotificationSetting(!pushNotificationSetting)}
                >
                  <span
                    aria-hidden="true"
                    className={`inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${pushNotificationButtonSetting.innerButtonClass}`}
                  ></span>
                </button>
              </li>
              <li className="py-4 flex items-center justify-between" x-data="{ on: false }">
                <div className="flex flex-col">
                  <p className="text-sm font-medium text-gray-900" id="privacy-option-2-label">
                    {translate(intl, 'ENABLE_DESKTOP_NOTIFICATION')}
                  </p>
                  <p className="text-sm text-gray-500" id="privacy-option-2-description">
                    {translate(intl, 'ENABLE_DESKTOP_NOTIFICATION_DESCRIPTION')}
                  </p>
                </div>
                <button
                  type="button"
                  className={`ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${desktopNotificationButtonSetting.buttonClass}`}
                  onClick={() => setDesktopNotificationSetting(!desktopNotificationSetting)}
                >
                  <span
                    aria-hidden="true"
                    className={`inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${desktopNotificationButtonSetting.innerButtonClass}`}
                  ></span>
                </button>
              </li>
              <li className="py-4 flex items-center justify-between" x-data="{ on: true }">
                <div className="flex flex-col">
                  <p className="text-sm font-medium text-gray-900" id="privacy-option-3-label">
                    {translate(intl, 'SUBSCRIBE_PRICE_UPDATE')}
                  </p>
                  <p className="text-sm text-gray-500" id="privacy-option-3-description">
                    {translate(intl, 'SUBSCRIBE_PRICE_UPDATE_DESCRIPTION')}
                  </p>
                </div>
                <button
                  type="button"
                  className={`ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${priceUpdateNotificationButtonSetting.buttonClass}`}
                  onClick={() => setPriceUpdateNotificationSetting(!priceUpdateNotificationSetting)}
                >
                  <span
                    aria-hidden="true"
                    className={`inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${priceUpdateNotificationButtonSetting.innerButtonClass}`}
                  ></span>
                </button>
              </li>
            </ul>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              disabled={loadingState}
              className="bg-sss-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              onClick={async (event) => {
                event.preventDefault();
                if (appContext.firestore) {
                  setLoadingState(true);
                  await appContext.firestore.doc(`users/${appContext.user.uid}`).update({
                    pushNotifications: pushNotificationSetting,
                    desktopNotifications: desktopNotificationSetting,
                    priceUpdateNotifications: priceUpdateNotificationSetting,
                  });
                  setLoadingState(false);
                  appContext.toaster.pushSuccessToaster('Success updating your notification settings');
                }
              }}
            >
              {loadingState ? <LoadingSpinner /> : ''}
              {translate(intl, 'SAVE')}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SettingsNotificationComponent;
