import React, { useEffect, useState } from 'react';
import {
  convertDateFromString,
  convertIDRFormat,
  filterChartDataWithSameTimePropertyValue,
  getPriceChangesProperty,
  translate,
} from 'common/utils/global.utils';
import { CommodityPriceType, CommodityType } from 'common/interfaces/commodity.interface';
import TVChartComponent from 'components/chart/TVChartComponent';
import { Transition } from '@headlessui/react';
import { useIntl } from 'react-intl';
import { ChartDataType, ChartType } from 'common/interfaces/chart.interface';
import { observer } from 'mobx-react';
import { useAppContext } from 'common/contexts/AppContext';

interface CommodityCardComponentType {
  commodity: CommodityType;
}

const CommodityCardComponent: React.FC<CommodityCardComponentType> = observer(
  ({ commodity }: CommodityCardComponentType) => {
    const intl = useIntl();
    const appContext = useAppContext();
    const [payload, setPayload] = useState({
      id: '',
      chartType: ChartType.LINE,
      priceCurrency: '',
      data: [
        {
          time: '',
          open: 0,
          high: 0,
          low: 0,
          close: 0,
          value: 0,
        },
      ],
      symbol: '',
    });
    const [changesProp, setChangesProp] = useState({
      changes: 0,
      bgClassName: '',
      fontColor: '',
      changesLabel: '',
    });
    const [commodityPricesState, setCommodityPricesState] = useState<CommodityPriceType[]>([
      {
        id: '',
        price: 0,
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        timestamp: '',
      },
    ]);

    useEffect(() => {
      if (commodityPricesState[0]) {
        if (commodityPricesState[0].id) {
          let chartData: ChartDataType[] = [];

          commodityPricesState.map((commodityPriceData) => {
            if (commodityPriceData) {
              chartData.push({
                time: convertDateFromString(commodityPriceData.timestamp),
                value: commodityPriceData.price,
                open: commodityPriceData.price,
                high: commodityPriceData.price,
                low: commodityPriceData.price,
                close: commodityPriceData.price,
              });
            }
          });

          //Filter chart data with same time value.
          chartData = filterChartDataWithSameTimePropertyValue(chartData, true).reverse();

          const lastPrices = chartData.slice(chartData.length - 2);
          if (lastPrices.length > 1) {
            setChangesProp(getPriceChangesProperty(lastPrices[1].value, lastPrices[0].value));
          } else {
            if (lastPrices.length > 0) {
              setChangesProp(getPriceChangesProperty(lastPrices[0].value, 0));
            } else {
              setChangesProp(getPriceChangesProperty(0, 0));
            }
          }

          setPayload({
            id: `chart-${commodity.id}${Math.random()}`,
            chartType: commodity.chartType,
            priceCurrency: commodity.priceChartCurrency,
            data: chartData,
            symbol: '',
          });
        }
      }
    }, [commodityPricesState]);

    useEffect(() => {
      setPayload({
        id: '',
        chartType: ChartType.LINE,
        priceCurrency: '',
        data: [
          {
            time: '',
            open: 0,
            high: 0,
            low: 0,
            close: 0,
            value: 0,
          },
        ],
        symbol: '',
      });
      setCommodityPricesState(commodity.prices);
    }, [appContext.commodities]);

    if (commodity.chartSymbol) {
      payload.symbol = commodity.chartSymbol;
    }
    return (
      <div className="flex flex-col bg-white overflow-hidden shadow rounded-md h-full">
        <div className="text-sm font-medium text-gray-500 pl-3 pt-3">{commodity.displayName}</div>
        <div className="p-3 lg:flex justify-between h-16">
          <div className="lg:flex items-center leading-6 text-3xl text-gray-900 mb-10 font-semibold pt-3">
            <div>
              <div className="text-xl font-semibold text-gray-900">{convertIDRFormat(commodity.price)}</div>
              <div className={`text-sm font-semibold ${changesProp.fontColor}`}>{changesProp.changesLabel}</div>
            </div>
          </div>
          <div className="flex items-center bg-sss-200 rounded-md px-5 py-8 text-white font-semibold">
            {commodity.displayCode}
          </div>
        </div>

        <Transition show={commodity.isPPN}>
          <div className="flex pl-3">
            <span className="inline-flex text-sm">
              <span className="inline-flex text-xs text-gray-500 font-medium">
                {translate(intl, 'FACTORY_CARD_PPN', 'price inc 10% PPN')}
              </span>
            </span>
          </div>
        </Transition>
        <div className="px-3 py-2 flex justify-between">
          <div className="flex space-x-6">
            <span className="inline-flex text-xs text-gray-500 font-medium">
              {convertDateFromString(commodity.timestamp, 'DD MMMM YYYY HH:mm:ss')}
            </span>
          </div>
        </div>
        {payload.id && payload.data[0] && payload.data[0].time && (
          <div id={payload.id} className="h-full w-full min-h-360px">
            <TVChartComponent payload={payload} />
          </div>
        )}
      </div>
    );
  },
);

export default CommodityCardComponent;
