import { Transition } from '@headlessui/react';
import { FactoryType } from 'common/interfaces/factory.interface';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

interface FactoryDetailSelectComponentType {
  factories: FactoryType[];
  factoryName?: string;
  selectType: string;
}

const FactoryDetailSelectComponent: React.FC<FactoryDetailSelectComponentType> = ({
  factories,
  factoryName,
  selectType,
}: FactoryDetailSelectComponentType) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="inline-flex text-left w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-100 text-sm lg:text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {factoryName}
        <svg
          className="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </button>

      <Transition show={isOpen}>
        <div
          className="absolute z-50 mx-auto mt-1 w-11/12 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {factories.map((factory, id) => (
              <div
                key={id}
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                role="menuitem"
                id="menu-item-0"
                onClick={() => {
                  setIsOpen(false);
                  history.push(`/${selectType}/detail/${factory.id}`);
                }}
              >
                {factory.displayName}
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default FactoryDetailSelectComponent;
