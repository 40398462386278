import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AccountOtpComponent from 'components/account/AccountOtpComponent';
import Logo from 'components/logo/Logo';
import { useIntl } from 'react-intl';
import { translate } from 'common/utils/global.utils';
import { useHistory } from 'react-router';
import { useAppContext } from 'common/contexts/AppContext';

const OtpPage: React.FC = (event) => {
  const appContext = useAppContext();
  const firebaseAuth = appContext.firebaseAuth;
  const history = useHistory<{ username: string; uid: string }>();
  const verificationMethod = 'EMAIL';
  let verificationId = '';
  let username = '';
  if (history.location.state) {
    verificationId = history.location.state.uid ?? '';
    username = history.location.state.username ?? '';
  }
  const intl = useIntl();
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  const onOtpSubmit = async (verificationCode: string, setLoadingState: Dispatch<SetStateAction<boolean>>) => {
    const checkOtpResponse = await appContext.accountService
      .checkOtp(verificationId, verificationCode)
      .catch((error) => {
        appContext.toaster.pushErrorToaster(error.message);
      });

    if (checkOtpResponse) {
      if (!checkOtpResponse.error) {
        firebaseAuth?.signInWithCustomToken(checkOtpResponse.result).then((signInResponse) => {
          if (signInResponse.user) {
            setLoadingState(false);
            appContext.toaster.pushSuccessToaster(`Welcome back ${signInResponse.user.displayName}`);
            history.push('/');
          } else {
            appContext.toaster.pushErrorToaster('Invalid Code!');
          }
        });
      } else {
        appContext.toaster.pushErrorToaster(checkOtpResponse.error.message);
      }
    } else {
      appContext.toaster.pushErrorToaster('Invalid Code!');
    }
    setLoadingState(false);
  };

  const onOtpResend = async () => {
    const resendOtpResponse = await appContext.accountService.resendOtp(verificationId, username, verificationMethod);
    if (resendOtpResponse && resendOtpResponse.result) {
      if (resendOtpResponse.result.error) {
        appContext.toaster.pushErrorToaster(resendOtpResponse.result.error.message);
        history.push('/home');
      } else {
        appContext.toaster.pushSuccessToaster(`OTP sent to ${username}`);
      }
    }
  };
  return (
    <>
      <AccountOtpComponent
        logo={Logo}
        title={translate(intl, 'accountOtpTitle', 'Confirm your identity')}
        username="username"
        actions={{ onOtpResend, onOtpSubmit }}
      />
    </>
  );
};

export default OtpPage;
