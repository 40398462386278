import React, { useEffect, useState } from 'react';
import Logo, { LogoVariantType } from 'components/logo/Logo';
import { useHistory } from 'react-router-dom';

const HomePage: React.FC = () => {
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setIsDoneLoading(true);
  }, []);
  return (
    <div className="h-full bg-white lg:bg-gray-50 flex flex-col py-12 px-0 lg:px-2">
      <div className="flex-1 mx-auto h-full w-full lg:w-1/2">
        <div className="bg-white py-4 px-4 lg:shadow rounded-lg h-full">
          <div className="pb-20 lg:pb-0">
            <Logo variant={LogoVariantType.HOME_PAGE} />
          </div>
          <div className="mt-8 w-full">
            <button
              type="submit"
              onClick={() => history.push('/account/sign-in')}
              className="w-full lg:w-2/3 lg:mx-auto lg:flex lg:justify-center py-2 px-8 border border-transparent rounded-md shadow-md text-2xl lg:text-lg  font-medium text-white bg-sss-300 hover:bg-sss-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sss-500"
            >
              Sign In
            </button>

            <div className="mt-4 text-lg mx-auto lg:text-sm lg:mt-0">
              <span className="w-full flex justify-center lg:flex lg:justify-center lg:pt-4">
                Don&apos;t have an account?
                <a href="mailto:projectsubur@gmail.com" className="text-sss-600 hover:text-sss-500 font-bold pl-1">
                  Contact Us
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
