import React, { useEffect, useState } from 'react';
import PublicLayout from 'components/public/PublicLayout';
import Logo from 'components/logo/Logo';
import SimpleNagivationComponent, { NavigationPosition } from 'components/navigation/SimpleNagivationComponent';
import ProfileNavigationComponent from 'components/navigation/ProfileNavigationComponent';
import { setNavigationActive } from 'common/utils/global.utils';
import { RoutesType } from 'common/interfaces/navigation.interface';
import {
  authenticatedActions,
  guestActions,
  settingsNavigationProp,
  simpleNavigationActionsProp,
} from 'common/data/global.data';
import SettingsProfileComponent from 'components/settings/SettingsProfileComponent';
import SettingsPreferenceComponent from 'components/settings/SettingsPreferenceComponent';
import SettingsNotificationComponent from 'components/settings/SettingsNotificationComponent';
import SettingsNavigationComponent from 'components/settings/SettingsNavigationComponent';
import { useAppContext } from 'common/contexts/AppContext';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import PagePlaceholder from 'components/placeholder/PagePlaceholder';

const SettingPage: React.FC = () => {
  const appContext = useAppContext();
  const currentNavigations: RoutesType[] = setNavigationActive(simpleNavigationActionsProp, '/settings');
  const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false);
  useEffect(() => {
    if (appContext.factories.length) {
      setIsDoneLoading(true);
    } else {
      setTimeout(() => setIsDoneLoading(true), 3500);
    }
  }, []);
  const slots = {
    main: <SimpleNagivationComponent actions={currentNavigations} position={NavigationPosition.SIDE} />,
    secondary: (
      <ProfileNavigationComponent
        isAuthenticated={appContext.isAuthenticated}
        imageUrl=""
        authenticatedActions={authenticatedActions}
        guestActions={guestActions}
      />
    ),
    content: (
      <div className="py-6 lg:py-10 mx-auto w-full">
        <div>
          <main className="px-4">
            <div>
              {/* <SettingsNavigationComponent actions={settingsNavigationProp} /> */}
              <div>
                <SettingsProfileComponent />
                <SettingsPreferenceComponent />
                <SettingsNotificationComponent />
                {/* <AccountsHistoryComponent /> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    ),
  };
  return (
    <ReactPlaceholder ready={isDoneLoading && appContext.isAuthenticated} customPlaceholder={<PagePlaceholder />}>
      <PublicLayout logo={Logo} slots={slots} actions={currentNavigations} searchPlaceholder="Search here" />
    </ReactPlaceholder>
  );
};

export default SettingPage;
