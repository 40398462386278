import Logo, { LogoVariantType } from 'components/logo/Logo';
import React from 'react';
import { TextBlock, MediaBlock, TextRow, RectShape, RoundShape } from 'react-placeholder/lib/placeholders';

const PagePlaceholder: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 show-loading-animation">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex px-2 lg:px-0">
              <Logo variant={LogoVariantType.SMALL} link="/" />
              <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                <a
                  href="#"
                  className="border-sss-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  <RectShape color="gray" style={{ width: 50, height: 30 }} />
                </a>
                <a
                  href="#"
                  className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  <RectShape color="gray" style={{ width: 50, height: 30 }} />
                </a>
                <a
                  href="#"
                  className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  <RectShape color="gray" style={{ width: 50, height: 30 }} />
                </a>
              </div>
            </div>
            <div className="flex items-center lg:hidden">
              <RectShape color="gray" style={{ width: 50, height: 30 }} />
            </div>

            <div className="hidden lg:ml-4 lg:flex lg:items-center">
              <div className="ml-4 relative flex-shrink-0">
                <div>
                  <button
                    type="button"
                    className="bg-white rounded-full flex text-sm focus:outline-none"
                    id="user-menu"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <RoundShape color="gray" style={{ width: 30, height: 30 }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="py-6 lg:py-10">
        <div className="max-w">
          <main className="px-4">
            <div>
              <h1 className="text-lg leading-6 font-medium text-gray-900">
                <TextBlock rows={1} color="gray" />
              </h1>
              <ul className="mt-4 gap-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                <li className="mb-10 shadow-lg">
                  <article className="relative px-4 bg-white shadow p-6">
                    <div className="flex">
                      <div className="flex-grow pr-5">
                        <div className="text-xl font-semibold text-gray-900">
                          <TextBlock rows={1} color="gray" />
                        </div>
                        <div className="mt-2 text-sm text-gray-700 space-y-4">
                          <TextBlock rows={1} color="gray" />
                        </div>
                      </div>
                      <div className="flex-shrink ml-auto">
                        <div className="flex flex-col text-right">
                          <div>
                            <div className="text-xs font-medium text-gray-500">
                              <TextBlock rows={1} color="gray" />
                            </div>
                            <div className="mt-1 text-xl inline-flex items-center text-sm font-medium bg-green-600 px-2 py-1 rounded-md text-white">
                              <TextBlock rows={1} color="gray" />
                            </div>
                          </div>
                          <TextBlock rows={1} color="gray" />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mb-4 flex justify-between">
                      <div className="flex space-x-6">
                        <span className="inline-flex text-xs text-gray-500 font-medium">
                          <TextBlock rows={1} color="gray" />
                        </span>
                      </div>
                      <div className="flex">
                        <span className="inline-flex text-sm">
                          <span className="inline-flex text-xs text-gray-500 font-medium">
                            <TextBlock rows={1} color="gray" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <a href="#">
                      <span className="absolute inset-x-0 bg-gray-200 px-4 py-4 rounded-t-none rounded-md shadow-lg">
                        <span className="text-sm font-medium text-green-600 hover:text-green-500">
                          <TextBlock rows={1} color="gray" />
                        </span>
                      </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>

            <div className="mt-10">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                <TextBlock rows={1} color="gray" />
              </h3>
              <div className="mt-5 gap-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                <div className="flex flex-col bg-white overflow-hidden shadow rounded-md h-full">
                  <div className="pb-10">
                    <TextRow color="gray" />
                  </div>
                  <RectShape color="gray" style={{ width: '100%', height: 200 }} />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PagePlaceholder;
