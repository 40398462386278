import React from 'react';
import { ContractType } from 'common/interfaces/contract.interface';
import ContractListItemComponent from './ContractListItemComponent';

interface ContractListComponentType {
  contract: ContractType;
}

const ContractListComponent: React.FC<ContractListComponentType> = ({ contract }: ContractListComponentType) => {
  return (
    <div>
      <h3 className="px-2 text-lg leading-6 font-medium text-gray-900">{contract.title}</h3>
      {contract.contractListItem[0] && contract.contractListItem[0].id ? (
        <div className="mt-4 bg-white shadow rounded-md">
          <ul className="divide-y divide-gray-200">
            {contract.contractListItem.slice(0, contract.listLimit).map((contractList, id) => (
              <ContractListItemComponent
                key={id}
                id={contractList.id}
                price={contractList.price}
                timestamp={contractList.timestamp}
                quantity={contractList.quantity}
                quantityUnit={contractList.quantityUnit}
                status={contractList.status}
                action={contractList.action}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div className="p-2 mb-10 text-gray-500">Data not found</div>
      )}
    </div>
  );
};

export default ContractListComponent;
