import { AppContext, useAppContext } from 'common/contexts/AppContext';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from 'languages/en-US.json';
import Indonesian from 'languages/id.json';
import firebase from 'firebase/app';
import 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import 'firebase/firestore';
import 'firebase/storage';
import { AccountService } from 'common/services/AccountService';
import { UserType } from 'common/interfaces/account.interface';
import { useHistory } from 'react-router';
import { userInitialValue } from 'common/data/global.data';
import { FactoryType } from 'common/interfaces/factory.interface';
import { factoryStore } from 'common/stores/factory.store';
import { CommodityType } from 'common/interfaces/commodity.interface';
import { commodityStore } from 'common/stores/commodity.store';
import { userStore } from 'common/stores/user.store';

interface ProviderType {
  children: React.ReactNode;
}

let lang: {};
const changeLanguage = (locale: string) => {
  if (locale === 'en-US') {
    lang = English;
  } else {
    lang = Indonesian;
  }
};

const firebaseConfig = {
  apiKey: 'AIzaSyAZwUJ37ahMBxqX4lXgXMnjtpy9e_uQZ7I',
  authDomain: 'sss-subur.firebaseapp.com',
  databaseURL: 'https://sss-subur.firebaseio.com',
  projectId: 'sss-subur',
  storageBucket: 'sss-subur.appspot.com',
  messagingSenderId: '1048027768246',
  appId: '1:1048027768246:web:7bce099cdf0b30bbdc8d17',
  measurementId: 'G-PRPNHM82GB',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase
    .firestore()
    .enablePersistence()
    .catch(() => {});
}

const Provider = ({ children }: ProviderType) => {
  const appContext = useAppContext();
  const history = useHistory();
  const [user, setUser] = useState<UserType>(userInitialValue);
  const [factories, setFactories] = useState<FactoryType[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigatorLanguage = navigator.language;
  const DEFAULT_TOASTER_INTERVAL = 8000;
  const [toasterContent, setToasterContent] = useState(appContext.toaster.content);
  const pushToaster = (
    type: string,
    message: string,
    linkLiteral?: string,
    linkPath?: string,
    interval: number = DEFAULT_TOASTER_INTERVAL,
  ) => {
    setToasterContent((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        type: type,
        message: message,
        linkLiteral: linkLiteral,
        linkPath: linkPath,
        interval: interval,
      },
    ]);
  };

  const pushSuccessToaster = (message: string, linkLiteral?: string, linkPath?: string) => {
    pushToaster('success', message, linkLiteral, linkPath);
  };

  const pushWarningToaster = (message: string, linkLiteral?: string, linkPath?: string) => {
    pushToaster('warning', message, linkLiteral, linkPath);
  };

  const pushErrorToaster = (message: string, linkLiteral?: string, linkPath?: string) => {
    pushToaster('error', message, linkLiteral, linkPath);
  };

  const closeToaster = (id: string) => {
    setToasterContent((prevState) => prevState.filter((e) => e.id != id));
  };

  changeLanguage(navigatorLanguage);
  const [locale, setLocale] = useState(navigatorLanguage);
  const [message, setMessage] = useState(lang);

  const setLanguage = (newLocale: string) => {
    setLocale(newLocale);
    changeLanguage(newLocale);
    setMessage(lang);
  };

  const firebaseAuth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();

  const [commodities, setCommodities] = useState<CommodityType[]>([]);
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setIsAuthenticated(true);
        userStore.setUserList(firestore, userAuth, setUser, setLanguage);
        if (user.factoryIds && user.factoryIds.length) {
          user.factoryIds.map((factoryId: string) => {
            factoryStore.setFactoryList(firestore, setFactories, factoryId);
          });
        }
        commodityStore.setCommodityList(firestore, setCommodities);
      } else {
        if (
          !history.location.pathname.includes('/account/reset-password/') &&
          !history.location.pathname.includes('/links/')
        ) {
          history.push('/home');
        }
      }
    });
    // Prevent Firestore Memory Leak
    return () => unsubscribe();
  }, [user.uid]);

  const toaster = {
    content: toasterContent,
    pushToaster: pushToaster,
    pushSuccessToaster: pushSuccessToaster,
    pushWarningToaster: pushWarningToaster,
    pushErrorToaster: pushErrorToaster,
    closeToaster: closeToaster,
  };

  const accountService = new AccountService(process.env.REACT_APP_FIREBASE_CLOUD_URL!);

  return (
    <AppContext.Provider
      value={{
        user,
        factories,
        commodities,
        isAuthenticated,
        locale,
        setLanguage,
        firebaseAuth,
        firestore,
        storage,
        toaster,
        accountService,
      }}
    >
      <IntlProvider locale={locale} messages={message}>
        {children}
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default Provider;
